import React, { FC, useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles({
  iconPositive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    width: 20,
    height: 20,
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "grey",
    borderStyle: "solid",
  },
  iconNegative: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    width: 20,
    height: 20,
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "grey",
    borderStyle: "solid",
  },
  checkedIconPositive: {
    backgroundColor: "#00970e",
    borderWidth: 1,
    borderColor: "#1b5e20",
  },
  checkedIconNegative: {
    backgroundColor: "#970000",
    borderWidth: 1,
    borderColor: "#700202",
  },
});

interface CheckboxCustomProps extends CheckboxProps {
  ispositivechecked?: boolean;
  isnegativechecked?: boolean;
}

// Inspired by blueprintjs
function StyledCheckbox(props: CheckboxCustomProps) {
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Checkbox
        color="default"
        checkedIcon={
          <span
            className={clsx(classes.iconNegative, classes.checkedIconNegative)}
          >
            <FontAwesomeIcon
              icon={faTimes}
              color={"white"}
              style={{
                width: "60%",
                height: "60%",
              }}
            />
          </span>
        }
        icon={<span className={classes.iconNegative} />}
        checked={props.isnegativechecked}
        onChange={props.onChange}
      />
      <Checkbox
        color="default"
        checkedIcon={
          <span
            className={clsx(classes.iconPositive, classes.checkedIconPositive)}
          >
            <FontAwesomeIcon
              icon={faCheck}
              color={"white"}
              style={{
                width: "60%",
                height: "60%",
              }}
            />
          </span>
        }
        icon={<span className={classes.iconPositive} />}
        checked={props.ispositivechecked}
        onChange={props.onChange}
      />
    </div>
  );
}

type OptionProps = {
  idx: any;
  label: string;
  ispositivechecked?: boolean;
  isnegativechecked?: boolean;
  id?: any;
};

type MultiCheckboxListProps = {
  options: Array<OptionProps>;
  value?: any;
  handleOnChangeMultiCheckbox?: any;
  onClickRowInfo?: any;
};

const MultiCheckboxList: FC<MultiCheckboxListProps> = (props) => {
  var [options, setOptions] = useState<any>([]);

  useEffect(() => {
    if (props.value) {
      const optionsCopy = props.options.map((x: any) => ({
        idx: x.idx,
        label: x.label,
        ispositivechecked: props.value[x.id]
          ? props.value[x.id].value
          : x.ispositivechecked,
        isnegativechecked: props.value[x.id]
          ? !props.value[x.id].value
          : x.isnegativechecked,
        id: x.id ? x.id : "",
        info: x.info,
        title: x.title ? x.title : "",
        images: x.images,
      }));
      setOptions(optionsCopy);
    } else {
      setOptions(props.options);
    }
  }, [props.options]);

  const handleOnChange = (idx: any) => {
    const optionsCopy = options.map((x: any) =>
      x.idx === idx
        ? {
            idx: x.idx,
            label: x.label,
            ispositivechecked: x.isnegativechecked
              ? true
              : x.ispositivechecked
              ? false
              : true,
            isnegativechecked: x.ispositivechecked
              ? true
              : x.isnegativechecked
              ? false
              : true,
            id: x.id ? x.id : "",
            info: x.info,
            title: x.title,
            images: x.images,
          }
        : x
    );
    setOptions(optionsCopy);
    props.handleOnChangeMultiCheckbox(optionsCopy);
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      {options.map((el: any) => (
        <div
          key={el.idx}
          style={{
            display: "flex",
            flex: 1,
            width: "100%",
            borderStyle:
              el.idx !== options.length - 1 ? "none none solid none" : "none",
            borderWidth: 1,
            borderColor: "rgba(127,127,127,0.25)",
            alignItems: "center",
          }}
        >
          {el.info ? (
            <InfoOutlinedIcon
              style={{
                fill: "#4e88f4",
                cursor: "pointer",
                width: 32,
              }}
              onClick={() => {
                props.onClickRowInfo ? props.onClickRowInfo(el) : null;
              }}
            />
          ) : (
            <div style={{ width: 32 }} />
          )}
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-start",
              alignItems: "center",
              fontSize: 14,
              fontFamily: "Roboto",
              userSelect: "none",
            }}
          >
            {el.label}
          </div>
          <StyledCheckbox
            ispositivechecked={el.ispositivechecked}
            isnegativechecked={el.isnegativechecked}
            onChange={() => {
              handleOnChange(el.idx);
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default MultiCheckboxList;
