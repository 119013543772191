import React, { FC, Fragment, useState, useEffect } from "react";
import "./MultiFileUpload.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import imageCompression from "browser-image-compression";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import Button from "@material-ui/core/Button";
import Typography from "../Typography";
type MultiFileUploadProps = {
  files?: any;
  setFiles?: any;
  chapterTitle?: string;
  setSnackbarSeverity?: any;
  setSnackbarText?: any;
  setIsSnackbarOpen?: any;
};

const MultiFileUpload: FC<MultiFileUploadProps> = ({
  files,
  chapterTitle,
  setFiles,
  setSnackbarSeverity,
  setSnackbarText,
  setIsSnackbarOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {}, []);
  function sleep(time?: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const compressOptions = {
    maxSizeMB: 1,
    useWebWorker: true,
  };

  const handleOnUploadFile = async (list: any = []) => {
    if (list.length > 0) {
      if (list.length + files.length <= 5) {
        setIsLoading(true);
        let images = Array.from(list);
        let newFiles: any = [];
        await Promise.all(
          images.map(async (image: any) => {
            let data = await readFileData(image);
            newFiles.push({
              uploadName: `${chapterTitle} - ${image.name}`,
              data,
              name: image.name,
            });
          })
        );
        setFiles((prevState: any) => [...prevState, ...newFiles]);
        setIsLoading(false);
      } else {
        setSnackbarSeverity("error");
        setSnackbarText("Max 5 bilder tillåts");
        setIsSnackbarOpen(true);
        sleep(3000).then(() => {
          setIsSnackbarOpen(false);
        });
      }
    }
  };

  const readFileData = async (image: File) => {
    let compressedFile = await imageCompression(image, compressOptions);
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(compressedFile);
    });
  };

  const handleOnRemoveFile = (fileName: string = "") => {
    setFiles((prevState: any) => [
      ...prevState.filter((file: any) => file.name !== fileName),
    ]);
  };

  const handleOnRemoveAllFiles = () => {
    setFiles([]);
  };

  return (
    <div className="multiImgContainer" id="fileUpload">
      {isLoading ? (
        <div className="loadingSpinner">
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <>
          <div className="multiButtonGroup">
            <Button
              style={{
                borderWidth: 0,
                width: "100%",
                marginBottom: "1em",
                color: "white",
                fontFamily: "Roboto",
                textTransform: "capitalize",
                borderRadius: 32,
                backgroundColor: "#195e8a",
              }}
              variant="contained"
              component="label"
            >
              <input
                type="file"
                hidden
                accept="image/*"
                multiple
                onChange={(e) => handleOnUploadFile(e.target.files)}
              />
              <AddAPhotoIcon />
              <Typography
                type="body1"
                value="Lägg till bilder (max 5)"
                style={{
                  textTransform: "none",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  color: "white",
                  userSelect: "none",
                  marginTop: "5px",
                  marginLeft: "10px",
                }}
              />
            </Button>
            {files.length > 0 ? (
              <Button
                style={{
                  borderWidth: 0,
                  color: "white",
                  width: "100%",
                  fontFamily: "Roboto",
                  textTransform: "capitalize",
                  borderRadius: 32,
                  backgroundColor: "#970000",
                  borderColor: "#700202",
                  marginBottom: "1em",
                }}
                variant="contained"
                component="label"
                onClick={(e: any) => handleOnRemoveAllFiles()}
              >
                <ClearAllIcon />
                <Typography
                  type="body1"
                  value="Ta bort bilder"
                  style={{
                    textTransform: "none",
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    color: "white",
                    userSelect: "none",
                    marginTop: "5px",
                    marginLeft: "10px",
                  }}
                />
              </Button>
            ) : null}
          </div>
          <div className="multiImages">
            <ul style={{ paddingLeft: "1em", marginTop: "0px" }}>
              {files.map((image: any) => {
                return (
                  <li key={image.name} className="listImage">
                    <IconButton
                      aria-label="delete"
                      className="deleteIconButton"
                      style={{
                        padding: 0,
                        marginRight: "10px",
                        color: "#970000",
                        fontFamily: "Roboto",
                        textTransform: "capitalize",
                      }}
                      onClick={(e: any) => handleOnRemoveFile(image.name)}
                    >
                      <ClearIcon />
                    </IconButton>
                    {image.name}
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

MultiFileUpload.defaultProps = {};
export default MultiFileUpload;
