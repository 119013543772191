import React, { FC, useState, useEffect } from "react";
import "./Documentation.css";

import Button from "../Button";

import Dialog from "../Dialog";
import Loading from "../Loading";
import Snackbar from "../Snackbar";

import Accordion from "../Accordion";
import Typography from "../Typography";
import MultiCheckboxList from "../MultiCheckboxList";
import TextField from "@material-ui/core/TextField";

import {
  addForm,
  sendInspectionCreateEvent,
  putStorageFile,
  updateForm,
  sendInspectionUpdateEvent,
} from "../../services/firebase";
import { dragProv } from "../../services/calculations";

import OPTIONS from "./options";

import { useHistory } from "react-router-dom";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Markdown from "markdown-to-jsx";
import FileUpload from "../FileUpload";
import MultiFileUpload from "../MultiFileUpload";
import Recommendation from "../Recommendation";
import scaffinspectLogoDark from "../../assets/logos/scaffinspectLogoDark.png";

type DocumentationProps = {
  form?: any;
  files?: any;
  setFiles?: any;
  multiFiles?: any;
  setMultiFiles?: any;
  isOffline?: boolean;
  handleOnClickBack?: any;
  handleOnChangeMultiCheckbox?: any;
  handleOnUpdatedDragprov?: any;
  handleOnChangeInputfield?: any;
  handleOnSentInspection?: any;
  handleOnClickInfo?: any;
};
const Documentation: FC<DocumentationProps> = ({
  form,
  files,
  setFiles,
  multiFiles,
  setMultiFiles,
  isOffline,
  handleOnClickBack,
  handleOnChangeMultiCheckbox,
  handleOnUpdatedDragprov,
  handleOnChangeInputfield,
  handleOnSentInspection,
  handleOnClickInfo,
}) => {
  let history = useHistory();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogInfoOpen, setIsDialogInfoOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [options3, setOptions3] = useState<any>({
    name: "3. Diagonalstagning",
    value: [],
  });
  const [dragprov, setDragprov] = useState<any>([]);
  const [maxDrag, setMaxDrag] = useState(0);
  const [row, setRow] = useState<any>({ title: "", info: "" });
  const [supplier, setSupplier] = useState<any>({});

  useEffect(() => {
    let antaldiagonalstagning = calculateDiagonalStagning();

    let options3 = OPTIONS.options3.value.map((x: any) => ({
      id: x.id,
      idx: x.idx,
      isnegativechecked: x.isnegativechecked,
      ispositivechecked: x.ispositivechecked,
      label:
        x.label === "Korrekt antal"
          ? x.label + ` ${antaldiagonalstagning}` + " st"
          : x.label,
      info: x.info,
      title: x.title,
      images: x.images,
    }));

    setOptions3((prevState: any) => ({
      ...prevState,
      value: options3,
    }));

    setSupplier(getSupplier(form.ställningsleverantör.value));
  }, [form]);

  useEffect(() => {
    let förankringshöjd: number = 2;
    switch (form.förankringshöjd.value) {
      case "var annan":
        förankringshöjd = 2;
        break;
      case "var tredje":
        förankringshöjd = 3;
        break;
      case "var fjärde":
        förankringshöjd = 4;
        break;
      case "fristående":
        förankringshöjd = 0;
        break;
      default:
        break;
    }

    let prov = dragProv(
      parseFloat(form.ställningshöjd.value),
      förankringshöjd,
      form.inklädnad.value,
      parseFloat(form.facklängd.value),
      parseFloat(form.terrängtyp.value),
      parseFloat(form.vindhastighet.value),
      form.ställningsleverantör.value
    );

    prov.length === 0
      ? setMaxDrag(0)
      : setMaxDrag(Math.max(...prov.map((p: any) => p.kraft)));

    let dragprovTemp = prov.map((x: any, idx: any) => ({
      idx: idx,
      label: `Dragprov, höjd: ${x.pos} m, kraft ${x.kraft} kN,`,
      ispositivechecked: false,
      isnegativechecked: true,
    }));

    setDragprov(dragprovTemp);
    if (dragprov.length === 0) handleOnUpdatedDragprov(dragprovTemp);
  }, [
    form.ställningshöjd.value,
    form.förankringshöjd.value,
    form.inklädnad.value,
    form.facklängd.value,
    form.terrängtyp.value,
    form.vindhastighet.value,
  ]);

  useEffect(() => {}, []);

  const calculateDiagonalStagning = () => {
    let antalfack =
      form.facklängd.value < 2
        ? 3
        : form.ställningslängd.value / form.facklängd.value;

    let antaldiagonalstagning = 0;
    if (antalfack > 5) {
      antaldiagonalstagning = (antalfack / 5) * (form.ställningshöjd.value / 2);
    } else {
      antaldiagonalstagning = form.ställningshöjd.value / 2;
    }

    return Math.round(antaldiagonalstagning);
  };

  function sleep(time?: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const getSupplier = (supplier: string = "") => {
    console.log(supplier);
    let res;
    switch (supplier) {
      case "haki":
        res = {
          name: "Haki",
          components: [
            {
              label: "Förankringsrör - Ø 12mm krok",
              force: "5,4 kN",
            },
            {
              label: "Förankringsrör - Ø 16mm krok",
              force: "9 kN",
            },
            {
              label: "Vinkelrät koppling (RA)",
              force: "8 kN*",
            },
            {
              label: "Vridbar koppling (SW)",
              force: "5 kN*",
            },
            {
              label: "VST-stag",
              force: "27 kN (drag)",
            },
          ],
          referenceList: [
            "SP Typkontrollintyg 14 55 15 (rörkoppling Haki)",
            "HAKI monteringsinstruktion",
          ],
        };
        break;
      case "pluseight":
        res = {
          name: "Pluseight",
          components: [
            {
              label: "Förankringsrör - Ø 16mm krok",
              force: "4 kN",
            },
            {
              label: "Vinkelrät koppling (RA)",
              force: "9,1 kN*",
            },
            {
              label: "Vridbar koppling (SW)",
              force: "9,1 kN*",
            },
            {
              label: "Atlaskoppling",
              force: "16 kN (drag)",
            },
          ],
          referenceList: [
            "SP typkontrollintyg SC0734-17 (rätvinklig resp. vridbar koppling Pluseight)",
            "Pluseight monteringsinstruktion",
          ],
        };
        break;
      case "layher":
      default:
        res = {
          name: "Layher",
          components: [
            {
              label: "Förankringsrör",
              force: "Information saknas",
            },
            {
              label: "Vinkelrät koppling (RA) 8* kN",
              force: "8 kN*",
            },
            {
              label: "Vridbar koppling (SW) 5* kN",
              force: "5 kN*",
            },
          ],
          referenceList: ["SP Typkontrollintyg 154803 (kopplingar Layher)"],
        };
        break;
    }
    return res;
  };

  const handleOnClickInfoSectionTitle = (title: string = "") => {
    if (title === "förankringskraft") {
      setRow({
        title: "Förankringskraft",
        info: (
          <div>
            <p>1 kN = 100 kg</p>
            <p>
              Nedan komponentinfo kan användas för att undersöka huruvida
              ställningens förankringskomponenter kan ta upp dragkraften.
            </p>
            <h4>Komponentinfo för {supplier.name}</h4>
            <table className={"AnchorTable"}>
              <thead>
                <tr>
                  <th>Komponent</th>
                  <th>Hållfasthet</th>
                </tr>
              </thead>
              <tbody>
                {supplier.components.map((component: any) => {
                  return (
                    <tr>
                      <td>{component.label}</td>
                      <td>{component.force}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className={"DisclaimerDiv"}>
              <p>*Avser tillåtna värden, inkluderat säkerhetsfaktor 1,65</p>
            </div>
            <h5>
              {supplier.referenceList.length > 1 ? "Referenslista" : "Referens"}
            </h5>
            <ul>
              {supplier.referenceList.map((reference: any) => {
                return <li id="reference">{reference}</li>;
              })}
            </ul>
          </div>
        ),
      });
      setIsDialogInfoOpen(true);
    }
  };

  const handleOnClickRowInfo = (row: any) => {
    setRow(row);
    setIsDialogInfoOpen(true);
  };

  const washForm = () => {
    const clean: any = {};
    clean.edit = form.edit;

    if (form.edit) {
      clean.oldIsoTimestamp = form.oldIsoTimestamp;
      clean.formId = form.formId;
    }

    clean.info = {
      label: "Projektinformation",
      value: [
        form.ställningsentreprenör,
        form.beställare,
        form.projektadress,
        form.ansvarig,
        form.skyddsombud,
        form.ställningsleverantör,
        form.väderskyddstak,
        form.ställningslängd,
        form.ställningshöjd,
      ],
    };
    clean.anchor = {
      label: "Förankringskraft",
      value: form.dragprov,
    };
    clean.checklist = {
      label: "Checklista",
      value: [
        {
          label: OPTIONS.options1.name,
          value: getWashedOptions1Values(),
        },
        {
          label: OPTIONS.options2.name,
          value: [
            form.godkändplacering,
            form.vförankringar,
            form.provdragningsprotokoll,
            form.notes2,
          ],
        },
        {
          label: OPTIONS.options3.name,
          value: [form.korrektantal, form.korrektplacering, form.notes3],
        },
        {
          label: OPTIONS.options4.name,
          value: [
            form.fulltintäckt,
            form.komponenterfastsatta,
            form.snubbelrisk,
            form.ställningsplank,
            form.avståndtillvägg,
            form.oberoendetillträde,
            form.notes4,
          ],
        },
        {
          label: OPTIONS.options5.name,
          value: getWashedOptions5Values(),
        },
        {
          label: OPTIONS.options6.name,
          value: getWashedOptions6Values(),
        },
        {
          label: OPTIONS.options7.name,
          value: [
            form.dimensioneringshandlingar,
            form.uppförandeplan,
            form.monteringsinstruktioner,
            form.ställningskontroll,
            form.riskbedömning,
            form.notes7,
          ],
        },
        {
          label: OPTIONS.options8.name,
          value: getWashedOptions8Values(),
        },
        {
          label: OPTIONS.options9.name,
          value: getWashedOptions9Values(),
        },
      ],
    };
    clean.decision = {
      label: "Beslut",
      value: [form.ställninggodkänd],
    };
    clean.notes = {
      label: "Anteckningar",
      value: form.notes,
    };

    clean.controlData = {
      label: "Ställningskontroll",
      value: [
        form.bygghiss,
        form.facklängd,
        form.förankringshöjd,
        form.förankringsyta,
        form.inklädnad,
        form.lastklass,
        form.ställningstyp,
        form.terrängtyp,
        form.typavarbete,
        form.underlag,
        form.underpallningensstorlek,
        form.vindhastighet,
        form.ställningsleverantör,
        form.väderskyddstak,
        form.ställningslängd,
        form.ställningshöjd,
        form.skyddmotfall,
      ],
    };

    return clean;
  };

  const sendForm = async (form: any) => {
    if (form.edit) {
      return updateForm(form);
    } else {
      return addForm(form);
    }
  };

  const postForm = async () => {
    setIsLoading(true);
    setIsDialogOpen(false);
    const cleanForm = washForm();
    const edit = cleanForm.edit;
    const uploadFiles = [...files, ...multiFiles];
    if (uploadFiles.length > 0) {
      cleanForm.files = true;
      await Promise.all(
        uploadFiles.map((file: any) =>
          putStorageFile(file.uploadName, file.data)
        )
      );
    } else {
      cleanForm.files = false;
    }
    sendForm(cleanForm)
      ?.then(() => {
        setIsSnackbarOpen(true);
        setSnackbarSeverity("success");

        let text = isOffline
          ? "Inspektion skickad"
          : edit
          ? "Inspektion updaterad"
          : "Inspektion skapad";

        setSnackbarText(text);
        sleep(3000).then(() => {
          setIsSnackbarOpen(false);
          setIsLoading(false);
          handleOnSentInspection();
        });

        let formValues = {
          files: files.length + multiFiles.length,
        };

        let unwantedFields = [
          "ansvarig",
          "beställare",
          "projektadress",
          "ställningsentreprenör",
          "skyddsombud",
        ];
        const unwantedPattern = new RegExp("(dragprov(_d+)?|(notesd*))");
        Object.entries(form).forEach(([key, value]: any) => {
          if (!unwantedFields.includes(key) && !unwantedPattern.test(key)) {
            formValues = { ...formValues, ...{ [key]: value.value } };
          }
        });

        if (edit) {
          sendInspectionUpdateEvent(formValues);
        } else {
          sendInspectionCreateEvent(formValues);
        }
      })
      .catch((err) => {
        setIsSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarText(err.message);

        sleep(3000).then(() => {
          setIsLoading(false);
          setIsSnackbarOpen(false);
          handleOnSentInspection();
        });
      });
  };

  const getOptions5Values = () => {
    let clonedOptions = [...OPTIONS.options5.value];
    if (!form.trafik.value) {
      clonedOptions = clonedOptions.filter(
        (opt: any) => opt.id !== "påkörningsskydd"
      );
    }
    if (!form.fallandeföremål.value) {
      clonedOptions = clonedOptions.filter(
        (opt: any) => opt.id !== "skyddstak"
      );
    }

    return clonedOptions;
  };

  const getWashedOptions5Values = () => {
    let res = [
      form.tillräckligräckeshöjd,
      form.överledare,
      form.förtätning,
      form.trafik,
      form.fallandeföremål,
      form.notes5,
    ];
    if (form.fallandeföremål.value) {
      res.push(form.skyddstak);
    }
    if (form.trafik.value) {
      res.push(form.påkörningsskydd);
    }

    return res;
  };

  const getOptions6Values = () => {
    let clonedOptions = [...OPTIONS.options6.value];
    if (!form.fleraställningsnivåer.value) {
      clonedOptions = clonedOptions.filter(
        (opt: any) => opt.id !== "dimfleraställningsnivåer"
      );
    }
    if (!form.tungutrustning.value) {
      clonedOptions = clonedOptions.filter(
        (opt: any) => opt.id !== "dimtungutrustning"
      );
    }
    if (!form.lastningmedlyftmaskin.value) {
      clonedOptions = clonedOptions.filter(
        (opt: any) => opt.id !== "godkändlastningmedlyftmaskin"
      );
    }

    return clonedOptions;
  };

  const getWashedOptions6Values = () => {
    let res = [
      form.fleraställningsnivåer,
      form.tungutrustning,
      form.lastningmedlyftmaskin,
      form.notes6,
    ];
    if (form.fleraställningsnivåer.value) {
      res.push(form.dimfleraställningsnivåer);
    }
    if (form.tungutrustning.value) {
      res.push(form.dimtungutrustning);
    }
    if (form.lastningmedlyftmaskin.value) {
      res.push(form.godkändlastningmedlyftmaskin);
    }

    return res;
  };

  const getOptions8Values = () => {
    if (form.pågåendeuppförande.value) {
      return OPTIONS.options8.value;
    }

    return OPTIONS.options8.value.slice(0, 1);
  };

  const getWashedOptions8Values = () => {
    if (form.pågåendeuppförande.value) {
      return [
        form.ställningstillträdespärrat,
        form.arbetsområdespärrat,
        form.särskildarisker,
        form.skyddsutrustning,
        form.pågåendeuppförande,
        form.skyddmotfall,
        form.notes8,
      ];
    }

    return [form.pågåendeuppförande, form.notes8];
  };

  const getOptions9Values = () => {
    if (form.lärling.value) {
      return OPTIONS.options9.value;
    }

    return OPTIONS.options9.value.slice(0, 3);
  };

  const getWashedOptions1Values = () => {
    let res = [form.spirorcentrerade, form.underpallning, form.notes1];
    if (form.lutandeunderlag.value) {
      res.push(form.killutandeunderlag);
    }

    return res;
  };

  const getOptions1Values = () => {
    if (form.lutandeunderlag.value) {
      return OPTIONS.options1.value;
    }

    return OPTIONS.options1.value.slice(0, 3);
  };

  const getWashedOptions9Values = () => {
    let res = [
      form.utbildningsintyg,
      form.rättutbildning,
      form.lärling,
      form.notes9,
    ];
    if (form.lärling.value) {
      res.push(form.lärlinggodkänd);
    }

    return res;
  };

  return (
    <div className="PreviewContainer">
      <div className="PreviewSub">
        <img
          style={{
            width: "20%",
            height: "20%",
            minWidth: 200,
            objectFit: "contain",
            backgroundColor: "white",
            marginTop: 50,
          }}
          src={scaffinspectLogoDark}
          alt="Scaffinspect"
        />
        <Dialog
          title={
            form.edit
              ? "Grattis, snart är byggställningen uppdaterad!"
              : "Grattis, snart är byggställningen inspekterad!"
          }
          text={
            isOffline
              ? "Genom att klicka Bekräfta nedan så är din inspektion färdig och skickas till din mailaddress"
              : form.edit
              ? "Genom att klicka Bekräfta nedan så kommer din inspektion uppdateras och ersätta din tidigare inspektion."
              : "Genom att klicka Bekräfta nedan så är din inspektion färdig och sparas i Mina projekt."
          }
          open={isDialogOpen}
          handleOnClickCancel={() => {
            setIsDialogOpen(false);
          }}
          handleOnClickConfirm={() => {
            postForm();
          }}
        />
        <Dialog
          open={isDialogInfoOpen}
          title={row.title}
          text={
            row.title !== "Förankringskraft" ? (
              <Markdown options={{ wrapper: "div" }}>{row.info}</Markdown>
            ) : (
              row.info
            )
          }
          images={row.images}
          content={
            <Button title="Ok" onClick={() => setIsDialogInfoOpen(false)} />
          }
        />
        <Loading isOpen={isLoading} />
        <Snackbar
          isOpen={isSnackbarOpen}
          text={snackbarText}
          variant={"filled"}
          severity={snackbarSeverity}
          autoHideDuration={5000}
        />
        <div className="InfoTopContainer">
          <div
            data-hj-suppress // Suppress hotjar tracking
            className="InfoContainer"
            id="summary"
            style={{ userSelect: "none" }}
          >
            <Typography
              type="h6"
              style={{ color: "white", userSelect: "none", marginBottom: 8 }}
              value="Projektinformation"
            />
            <Typography
              type="body2"
              value={`Företag: ${form.ställningsentreprenör.value}`}
            />
            <Typography
              type="body2"
              value={`Beställare: ${form.beställare.value}`}
            />
            <Typography
              type="body2"
              value={`Projektadress: ${form.projektadress.value}`}
            />
            <Typography
              type="body2"
              value={`E-postadress till BAS-U: ${form.ansvarig.value}`}
            />
            <Typography
              type="body2"
              value={`E-postadress till skyddsombud: ${form.skyddsombud.value}`}
            />
          </div>
          {dragprov.length !== 0 ? (
            <div className="InfoContainer">
              <div className="AnchorTitleContainer">
                <Typography
                  type="h6"
                  style={{ color: "black", userSelect: "none" }}
                  value="Förankringskraft"
                />
                <InfoOutlinedIcon
                  style={{ fill: "#4e88f4", marginLeft: 12, cursor: "pointer" }}
                  onClick={() =>
                    handleOnClickInfoSectionTitle("förankringskraft")
                  }
                />
              </div>
              <MultiCheckboxList
                options={dragprov}
                handleOnChangeMultiCheckbox={handleOnChangeMultiCheckbox}
                value={form}
              />
            </div>
          ) : null}
          <div className="InfoContainer">
            <Typography
              type="h6"
              style={{ color: "black", userSelect: "none", marginBottom: 12 }}
              value="Checklista"
            />
            <Accordion header={OPTIONS.options1.name}>
              <div className="AccordionGroup">
                <MultiCheckboxList
                  options={getOptions1Values()}
                  handleOnChangeMultiCheckbox={handleOnChangeMultiCheckbox}
                  value={form}
                  onClickRowInfo={handleOnClickRowInfo}
                />
                <TextField
                  style={{ marginTop: 10 }}
                  id="notes1"
                  label={form.notes1.label}
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    style: {
                      borderRadius: 0,
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                  value={form.notes1.value}
                  onChange={handleOnChangeInputfield}
                />
                <FileUpload
                  id={1}
                  chapterTitle={OPTIONS.options1.name}
                  setFiles={setFiles}
                  files={files}
                />
              </div>
            </Accordion>
            <Accordion header={OPTIONS.options2.name}>
              <div className="AccordionGroup">
                <MultiCheckboxList
                  options={OPTIONS.options2.value}
                  handleOnChangeMultiCheckbox={handleOnChangeMultiCheckbox}
                  value={form}
                  onClickRowInfo={handleOnClickRowInfo}
                />
                <TextField
                  style={{ marginTop: 10 }}
                  id="notes2"
                  label={form.notes2.label}
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    style: {
                      borderRadius: 0,
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                  value={form.notes2.value}
                  onChange={handleOnChangeInputfield}
                />
                <FileUpload
                  id={2}
                  chapterTitle={OPTIONS.options2.name}
                  setFiles={setFiles}
                  files={files}
                />
              </div>
            </Accordion>
            <Accordion header={OPTIONS.options3.name}>
              <div className="AccordionGroup">
                <MultiCheckboxList
                  options={options3.value}
                  handleOnChangeMultiCheckbox={handleOnChangeMultiCheckbox}
                  value={form}
                  onClickRowInfo={handleOnClickRowInfo}
                />
                <TextField
                  style={{ marginTop: 10 }}
                  id="notes3"
                  label={form.notes3.label}
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    style: {
                      borderRadius: 0,
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                  value={form.notes3.value}
                  onChange={handleOnChangeInputfield}
                />
                <FileUpload
                  id={3}
                  chapterTitle={OPTIONS.options3.name}
                  setFiles={setFiles}
                  files={files}
                />
              </div>
            </Accordion>
            <Accordion header={OPTIONS.options4.name}>
              <div className="AccordionGroup">
                <MultiCheckboxList
                  options={OPTIONS.options4.value}
                  handleOnChangeMultiCheckbox={handleOnChangeMultiCheckbox}
                  value={form}
                  onClickRowInfo={handleOnClickRowInfo}
                />
                <TextField
                  style={{ marginTop: 10 }}
                  id="notes4"
                  label={form.notes4.label}
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    style: {
                      borderRadius: 0,
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                  value={form.notes4.value}
                  onChange={handleOnChangeInputfield}
                />
                <FileUpload
                  id={4}
                  chapterTitle={OPTIONS.options4.name}
                  setFiles={setFiles}
                  files={files}
                />
              </div>
            </Accordion>
            <Accordion header={OPTIONS.options5.name}>
              <div className="AccordionGroup">
                <MultiCheckboxList
                  options={getOptions5Values()}
                  handleOnChangeMultiCheckbox={handleOnChangeMultiCheckbox}
                  value={form}
                  onClickRowInfo={handleOnClickRowInfo}
                />
                <TextField
                  style={{ marginTop: 10 }}
                  id="notes5"
                  label={form.notes5.label}
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    style: {
                      borderRadius: 0,
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                  value={form.notes5.value}
                  onChange={handleOnChangeInputfield}
                />
                <FileUpload
                  id={5}
                  chapterTitle={OPTIONS.options5.name}
                  setFiles={setFiles}
                  files={files}
                />
              </div>
            </Accordion>
            <Accordion header={OPTIONS.options6.name}>
              <div className="AccordionGroup">
                <MultiCheckboxList
                  options={getOptions6Values()}
                  handleOnChangeMultiCheckbox={handleOnChangeMultiCheckbox}
                  value={form}
                  onClickRowInfo={handleOnClickRowInfo}
                />
                <TextField
                  style={{ marginTop: 10 }}
                  id="notes6"
                  label={form.notes6.label}
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    style: {
                      borderRadius: 0,
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                  value={form.notes6.value}
                  onChange={handleOnChangeInputfield}
                />
                <FileUpload
                  id={6}
                  chapterTitle={OPTIONS.options6.name}
                  setFiles={setFiles}
                  files={files}
                />
              </div>
            </Accordion>
            <Accordion header={OPTIONS.options7.name}>
              <div className="AccordionGroup">
                <MultiCheckboxList
                  options={OPTIONS.options7.value}
                  handleOnChangeMultiCheckbox={handleOnChangeMultiCheckbox}
                  value={form}
                  onClickRowInfo={handleOnClickRowInfo}
                />
                <TextField
                  style={{ marginTop: 10 }}
                  id="notes7"
                  label={form.notes7.label}
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    style: {
                      borderRadius: 0,
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                  value={form.notes7.value}
                  onChange={handleOnChangeInputfield}
                />
                <FileUpload
                  id={7}
                  chapterTitle={OPTIONS.options7.name}
                  setFiles={setFiles}
                  files={files}
                />
              </div>
            </Accordion>
            <Accordion header={OPTIONS.options8.name}>
              <div className="AccordionGroup">
                <MultiCheckboxList
                  options={getOptions8Values()}
                  handleOnChangeMultiCheckbox={handleOnChangeMultiCheckbox}
                  value={form}
                  onClickRowInfo={handleOnClickRowInfo}
                />
                <TextField
                  style={{ marginTop: 10 }}
                  id="notes8"
                  label={form.notes8.label}
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    style: {
                      borderRadius: 0,
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                  value={form.notes8.value}
                  onChange={handleOnChangeInputfield}
                />
                <FileUpload
                  id={8}
                  chapterTitle={OPTIONS.options8.name}
                  setFiles={setFiles}
                  files={files}
                />
              </div>
            </Accordion>
            <Accordion header={OPTIONS.options9.name}>
              <div className="AccordionGroup">
                <MultiCheckboxList
                  options={getOptions9Values()}
                  handleOnChangeMultiCheckbox={handleOnChangeMultiCheckbox}
                  onClickRowInfo={handleOnClickRowInfo}
                  value={form}
                />
                <TextField
                  style={{ marginTop: 10 }}
                  id="notes9"
                  label={form.notes9.label}
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    style: {
                      borderRadius: 0,
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                  value={form.notes9.value}
                  onChange={handleOnChangeInputfield}
                />
                <FileUpload
                  id={9}
                  chapterTitle={OPTIONS.options9.name}
                  setFiles={setFiles}
                  files={files}
                />
              </div>
            </Accordion>
          </div>
          <div className="InfoContainer" id="notes">
            <Typography
              type="h6"
              style={{ color: "black", userSelect: "none", marginTop: "2%" }}
              value={"Beslut och anteckningar"}
            />
            <Recommendation form={form} maxDrag={maxDrag}></Recommendation>
            <MultiCheckboxList
              options={OPTIONS.optionsBeslut.value}
              handleOnChangeMultiCheckbox={handleOnChangeMultiCheckbox}
              value={form}
            />
            <TextField
              style={{ marginTop: 10 }}
              id="notes"
              label="Anteckningar"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              inputProps={{
                style: {
                  borderRadius: 0,
                  backgroundColor: "white",
                  color: "black",
                },
              }}
              value={form.notes.value}
              onChange={handleOnChangeInputfield}
            />
            <MultiFileUpload
              files={multiFiles}
              setFiles={setMultiFiles}
              chapterTitle={"Sammanfattning"}
              setSnackbarSeverity={setSnackbarSeverity}
              setSnackbarText={setSnackbarText}
              setIsSnackbarOpen={setIsSnackbarOpen}
            />
          </div>
          <div className="ButtonContainer-Document">
            <Button
              style={{
                display: "flex",
                marginRight: "1%",
                minWidth: "45%",
                color: "black",
              }}
              title="Tillbaka"
              type="secondary"
              onClick={handleOnClickBack}
            />
            <Button
              style={{ display: "flex", marginLeft: "1%", minWidth: "45%" }}
              title={
                isOffline === false
                  ? form.edit
                    ? "Uppdatera Projekt"
                    : "Spara Projekt"
                  : "Skicka Projekt"
              }
              type="primary"
              onClick={() => {
                setIsDialogOpen(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
Documentation.defaultProps = {};

export default Documentation;
