/** Debounce functionality */
function debounce(fn: () => any, ms: number) {
  let timer: any;
  return (_: any) => {
    clearTimeout(timer);
    timer = setTimeout((_: any) => {
      timer = null;
      fn();
    }, ms);
  };
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export { debounce, capitalizeFirstLetter };
