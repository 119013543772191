import React, { FC, useState, useEffect } from "react";
import Typography from "../Typography";
import "./Recommendation.css";
import OPTIONS from "../Control/options";

type RecommendationProps = {
  form?: any;
  maxDrag?: any;
};

const Recommendation: FC<RecommendationProps> = ({ form, maxDrag }) => {
  const [warnings, setWarnings] = useState<any>([]);
  const [criticals, setCriticals] = useState<any>([]);

  useEffect(() => {
    setWarnings(extractWarnings());
    setCriticals(extractCriticals());
  }, [form, maxDrag]);

  const extractWarnings = () => {
    let formWarnings = [];
    let arbetsTyp = OPTIONS.typavarbete.value.find(
      (el: any) => el.value === form.typavarbete.value
    )?.loadClass;
    if (arbetsTyp !== undefined && form.lastklass.value < arbetsTyp) {
      formWarnings.push(
        `kontrollera att ställningens lastklass LK${form.lastklass.value} är rimlig för arbetstypen ${form.typavarbete.value}`
      );
    }
    let maxForce = OPTIONS.förankringsyta.value.find(
      (el: any) => el.value === form.förankringsyta.value
    )?.maxForce;
    if (maxForce !== undefined && maxDrag > maxForce) {
      formWarnings.push(
        `kontrollera att förankringsytan (${form.förankringsyta.value}) med maxdragkraft ${maxForce} kN klarar ställningens dragkraft ${maxDrag} kN`
      );
    }
    if (form.tungutrustning.value && !form.dimtungutrustning.value) {
      formWarnings.push(
        "ställningen belastas med tung utrustning vilket den inte är dimensionerad för (AFS2013:4 §35)"
      );
    }
    if (!form.komponenterfastsatta.value) {
      formWarnings.push(
        "arbetsplanets komponenter oavsiktligen kan komma ur läge (AFS2013:4 §35)"
      );
    }
    if (
      !form.godkändplacering.value ||
      !form.provdragningsprotokoll.value ||
      !form.vförankringar.value
    ) {
      formWarnings.push("förankringar är bristande");
    }
    if (!form.spirorcentrerade.value) {
      formWarnings.push(
        "ställningens fötter ej vilar / är centrerade på underpallningen (AFS2013:4 §26)"
      );
    }
    if (!form.riskbedömning.value) {
      formWarnings.push("ställningen saknar riskbedömning (AFS2013:4 §59)");
    }

    return formWarnings;
  };

  const extractCriticals = () => {
    let formCriticals = [];
    if (
      form.förankringshöjd.value === "fristående" &&
      !form.dimensioneringshandlingar.value
    ) {
      formCriticals.push(
        "fristående ställning kräver dimensioneringshandlingar (AFS2013:4 §40)"
      );
    }
    if (
      !form.dimensioneringshandlingar.value &&
      (form.ställningsleverantör.value === "blandat" ||
        (form.väderskyddstak.value !== "nej" &&
          form.väderskyddstak.value !== form.ställningsleverantör.value))
    ) {
      formCriticals.push(
        "entreprenör blandar material från olika ställningsleverantörer vilker kräver dimensioneringshandlingar (AFS2013:4 §40)"
      );
    }
    if (form.trafik.value && !form.påkörningsskydd.value) {
      formCriticals.push(
        "ställningen saknar påkörningsskydd vilket medför risk för ras vid påkörning (AFS2013:4 §28)"
      );
    }
    if (!form.överledare.value) {
      formCriticals.push(
        "ställningen saknar överledare, mellanledare, och fotlist vilket medför fallrisk (AFS2013:4 §28)"
      );
    }
    if (!form.fulltintäckt.value) {
      formCriticals.push(
        "ställningen är inte fullt intäckt vilket medför fallrisk (AFS2013:4 §35)"
      );
    }
    if (!form.avståndtillvägg.value) {
      formCriticals.push(
        "avstånd till vägg är för stort vilket medför fallrisk (AFS2013:4 §31)"
      );
    }
    if (
      form.väderskyddstak.value !== "nej" &&
      !form.dimensioneringshandlingar.value
    ) {
      formCriticals.push(
        "ställningen har väderskydd vilket kräver dimensioneringshandlingar (AFS2013:4 §40)"
      );
    }

    return formCriticals;
  };

  return (
    <div className="recommendationContainer" id="recommendations">
      {criticals.length > 0 || warnings.length > 0 ? (
        <Typography
          type="body1"
          style={{ color: "black", userSelect: "none" }}
          value={"Rekommendation"}
        />
      ) : null}
      {criticals.length > 0 ? (
        <div className="criticalContainer">
          <Typography
            type="body2"
            style={{ color: "black", userSelect: "none", fontWeight: "bold" }}
            value={"Ställningen bör inte godkännas för att:"}
          />
          <ul className="criticalList">
            {criticals.map((critItem: String, i: Number) => (
              <li key={`critItem${i}`}>{critItem}</li>
            ))}
          </ul>
        </div>
      ) : null}
      {warnings.length > 0 ? (
        <div className="warningContainer">
          <Typography
            type="body2"
            style={{ color: "black", userSelect: "none", fontWeight: "bold" }}
            value={"Ställningen bör åtgärdas för att:"}
          />
          <ul className="warningList">
            {warnings.map((warnItem: String, i: Number) => (
              <li key={`warnItem${i}`}>{warnItem}</li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

Recommendation.defaultProps = {};
export default Recommendation;
