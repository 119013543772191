import React, { FC, Fragment, useState, useEffect } from "react";
import imageCompression from "browser-image-compression";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./FileUpload.css";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Button from "@material-ui/core/Button";
import Typography from "../Typography";
type FileUploadProps = {
  files?: any;
  setFiles?: any;
  id?: number;
  chapterTitle?: string;
};

const FileUpload: FC<FileUploadProps> = ({
  files,
  chapterTitle,
  id,
  setFiles,
}) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    files.map((file: any) => {
      if (file.id === id) {
        setImagePreviewUrl(file.data);
      }
    });
  }, []);

  const compressOptions = {
    maxSizeMB: 1,
    useWebWorker: true,
  };

  const handleOnUploadFile = async (list: any = []) => {
    if (list.length > 0) {
      setIsLoading(true);
      let reader = new FileReader();
      const image = list[0];
      const compressedFile = await imageCompression(image, compressOptions);
      reader.onloadend = () => {
        setFiles((prevState: any) => [
          ...prevState.filter((file: any) => file.id !== id),
          { id, uploadName: chapterTitle, data: reader.result },
        ]);
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(compressedFile);
      setIsLoading(false);
    }
  };

  const handleOnRemoveFile = () => {
    setFiles((prevState: any) => [
      ...prevState.filter((file: any) => file.id !== id),
    ]);
    setImagePreviewUrl(null);
  };

  return (
    <div className="imgContainer" id="fileUpload">
      <div className="imgButtonGroup">
        <Button
          style={{
            borderWidth: 0,
            marginRight: "1%",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            color: "white",
            fontFamily: "Roboto",
            textTransform: "capitalize",
            borderRadius: 32,
            backgroundColor: "#195E8A",
            marginBottom: "1em",
          }}
          variant="contained"
          component="label"
        >
          <input
            type="file"
            hidden
            accept="image/*"
            multiple={false}
            onChange={(e) =>
              handleOnUploadFile(e.target.files).then(() => {
                e.target.value = "";
              })
            }
          />
          <AddAPhotoIcon />
          <Typography
            type="body1"
            value={imagePreviewUrl === null ? "Lägg till bild" : "Ändra bild"}
            style={{
              textTransform: "none",
              fontFamily: "Roboto",
              color: "white",
              fontWeight: "bold",
              marginTop: "5px",
              userSelect: "none",
              marginLeft: "10px",
            }}
          />
        </Button>
        {imagePreviewUrl !== null ? (
          <Button
            style={{
              borderWidth: 0,
              marginRight: "1%",
              marginBottom: "4%",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              color: "white",
              fontFamily: "Roboto",
              textTransform: "capitalize",
              borderRadius: 32,
              backgroundColor: "#970000",
              borderColor: "#700202",
            }}
            variant="contained"
            component="label"
            onClick={handleOnRemoveFile}
          >
            <DeleteForeverIcon />
            <Typography
              type="body1"
              value="Ta bort bild"
              style={{
                fontFamily: "Roboto",
                color: "white",
                fontWeight: "bold",
                marginTop: "5px",
                userSelect: "none",
                marginLeft: "10px",
              }}
            />
          </Button>
        ) : null}
      </div>
      {isLoading ? (
        <div className="loadingSpinner">
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <>
          {imagePreviewUrl !== null ? (
            <div className="imgPreview">
              <img src={imagePreviewUrl} />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

FileUpload.defaultProps = {};
export default FileUpload;
