import React, { FC, useState, useEffect } from "react";
import { ContextMenu, MenuItem } from "react-contextmenu";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import EditIcon from "@material-ui/icons/Edit";
import ShareIcon from "@material-ui/icons/Share";
import "./Contextmenu.css";

type ContextmenuProps = {
  onClick?: any;
  contextId?: any;
  onShowContextmenu?: any;
};

const Contextmenu: FC<ContextmenuProps> = ({
  onClick,
  contextId,
  onShowContextmenu,
}) => {
  const [contextData, setContextData] = React.useState({});

  useEffect(() => {}, []);

  return (
    <div id="contextmenuContainer">
      <ContextMenu
        id={contextId}
        onShow={(e) => {
          if (e.detail.data !== undefined) {
            onShowContextmenu(e.detail.data.docId);
            setContextData(e.detail.data);
          }
        }}
      >
        <MenuItem onClick={() => onClick({ ...contextData, type: "öppna" })}>
          <OpenInNewIcon style={{ marginRight: 16, marginBottom: "-5px" }} />
          Öppna
        </MenuItem>
        <MenuItem onClick={() => onClick({ ...contextData, type: "redigera" })}>
          <EditIcon style={{ marginRight: 16, marginBottom: "-5px" }} />
          Redigera
        </MenuItem>
        <MenuItem onClick={() => onClick({ ...contextData, type: "dela" })}>
          <ShareIcon style={{ marginRight: 16, marginBottom: "-5px" }} />
          Dela - OBS mail kan hamna i skräppost
        </MenuItem>
        <MenuItem divider className="divider" />
        <MenuItem onClick={() => onClick({ ...contextData, type: "tabort" })}>
          <DeleteForeverIcon
            style={{ marginRight: 16, marginBottom: "-5px" }}
          />
          Ta bort
        </MenuItem>
      </ContextMenu>
    </div>
  );
};

Contextmenu.defaultProps = {};
export default Contextmenu;
