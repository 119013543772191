import axios from "axios";
import app from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";
import "firebase/firebase-storage";
import "firebase/analytics";
import "firebase/database";
import CONFIG from "./config";

const config = {
  apiKey: "AIzaSyA7IzZ5KnFBg-CKKD8sv3oOnINtQccdIDs",
  authDomain: "scaffinspect.firebaseapp.com",
  projectId: "scaffinspect",
  storageBucket: "scaffinspect.appspot.com",
  tmp_bucket: "scaffinspect-tmp",
  messagingSenderId: "948109493993",
  appId: "1:948109493993:web:65fb3d26b4c92fa6545278",
  measurementId: "G-QEM60TR55F",
};

if (!app.apps.length) {
  app.initializeApp(config);
}

/** Firebase services */
const auth = app.auth();
const db = app.firestore();
const storage = app.storage();
const tmp_storage = app.app().storage(config.tmp_bucket);
const analytics = app.analytics();

const loginUser = (email: string, password: string) => {
  return auth.signInWithEmailAndPassword(email, password);
};

const logoutUser = () => {
  return auth.signOut();
};

const resetPassword = (email: string) => {
  return auth.sendPasswordResetEmail(email);
};

const getUser = () => {
  return auth.currentUser;
};

const getUserClaims = () => {
  return getUser()
    ?.getIdTokenResult()
    .then((IdTokenResult) => {
      return IdTokenResult.claims;
    });
};

const getUserDocListener = (callback = (data: any) => {}) => {
  const user = getUser();

  if (!user) return null;

  return db
    .collection("users")
    .where("email", "==", user.email)
    .onSnapshot((userSnap) => {
      userSnap.forEach((userDoc) => {
        callback(userDoc.data());
      });
    });
};

const getUserProjects = () => {
  const user = getUser();
  if (!user) return null;

  return db.collection("forms").where("email", "==", user.email).get();
};

const getProjectByID = (id: string) => {
  return db.collection("forms").doc(id).get();
};

const setFirebaseUserProperties = (company: string) => {
  analytics.setUserProperties({ company });
};

const sendInspectionCreateEvent = (form: any) => {
  analytics.logEvent("inspection_created", form);
};

const sendInspectionUpdateEvent = (form: any) => {
  analytics.logEvent("inspection_updated", form);
};

const getUserData = () => {
  const user = getUser();
  if (!user) return null;

  return db.collection("users").where("email", "==", user.email).get();
};

const getUserFormPDfUrl = (timestamp_iso: string, id: string) => {
  const user = getUser();
  if (!user) return null;

  return storage
    .ref(`users/${user.email}/forms/scaffinspect-${timestamp_iso}_${id}.pdf`)
    .getDownloadURL();
};

const putStorageFile = (fileName: string, data: string) => {
  const user = getUser();
  if (!user) return null;
  // the return value will be a Promise
  return tmp_storage
    .ref(`users/${user.email}/tmp/${fileName}`)
    .putString(data, "data_url")
    .catch((error) => {
      console.log("One failed:", fileName, error.message);
    });
};

const deleteFile = (timestamp_iso: string, id: string) => {
  const user = getUser();
  if (!user) return null;
  return storage
    .ref(`users/${user.email}/forms/scaffinspect-${timestamp_iso}_${id}.pdf`)
    .delete()
    .then(() => {
      // Delete from storage, then firestore
      db.collection("forms").doc(id).delete();
    });
};

const authStateChanged = (callback?: any) => {
  auth.onAuthStateChanged(callback);
};

const getIdToken = () => {
  return auth.currentUser?.getIdToken(true);
};

const addForm = (form: any) => {
  return getIdToken()?.then((token) => {
    return axios.post(
      CONFIG.FIREBASE,
      { form },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  });
};

const updateForm = (form: any) => {
  return getIdToken()?.then((token) => {
    return axios.put(
      CONFIG.FIREBASE,
      { form },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  });
};

export {
  app,
  loginUser,
  logoutUser,
  resetPassword,
  getUser,
  getUserClaims,
  getUserData,
  getUserDocListener,
  getUserProjects,
  getUserFormPDfUrl,
  authStateChanged,
  getIdToken,
  addForm,
  updateForm,
  setFirebaseUserProperties,
  sendInspectionCreateEvent,
  sendInspectionUpdateEvent,
  putStorageFile,
  deleteFile,
  getProjectByID,
};
