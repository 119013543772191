import React, { FC } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import AccordionMaterial from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "../Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./Accordion.css";

type AccordionProps = { header?: string; children?: any };
const Accordion: FC<AccordionProps> = ({ header, children }) => {
  return (
    <AccordionMaterial>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          type="h6"
          value={header}
          style={{
            color: "black",
            userSelect: "none",
          }}
        />
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </AccordionMaterial>
  );
};

export default Accordion;
