import React, { FC, useState, useEffect } from "react";
import FullscreenContainer from "../../components/FullscreenContainer";
import NavBarContainer from "../../components/NavBarContainer";
import Stepper from "../../components/Stepper";
import Button from "../../components/Button";
import NewProject from "../../components/NewProject";
import Control from "../../components/Control";
import Documentation from "../../components/Documentation";
import Dialog from "../../components/Dialog";
import INIT from "./init";

import Drawer from "../../components/Drawer";
import Divider from "../../components/Divider";
import Typography from "../../components/Typography";
import HomeIcon from "@material-ui/icons/Home";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import AssignmentIcon from "@material-ui/icons/Assignment";

import WindMap from "../../assets/images/windmap.png";
import anchor from "../../assets/images/anchor.png";
import dimLength from "../../assets/images/dimLength.png";
import { ReactComponent as Terrangtyp0 } from "../../assets/images/Terrangtyp0.svg";
import { ReactComponent as Terrangtyp1 } from "../../assets/images/Terrangtyp1.svg";
import { ReactComponent as Terrangtyp2 } from "../../assets/images/Terrangtyp2.svg";
import { ReactComponent as Terrangtyp3 } from "../../assets/images/Terrangtyp3.svg";
import { ReactComponent as Terrangtyp4 } from "../../assets/images/Terrangtyp4.svg";

import { useHistory } from "react-router-dom";

import clsx from "clsx";
import "./FormScreen.css";

type UserData = {
  offlinePdf?: boolean;
  firstName?: string;
  lastName?: string;
  company?: string;
  email?: string;
  phone?: string;
  role?: string;
};
type FormScreenProps = {
  userData?: any;
  formData?: any;
  setEditForm?: any;
};
const FormScreen: FC<FormScreenProps> = ({
  userData,
  formData,
  setEditForm,
}) => {
  let history = useHistory();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState<any>(null);
  const [form, setForm] = useState<{ [index: string]: any }>({
    ...INIT,
    ...formData,
  });
  const [isOkToProceed, setIsOkToProceed] = useState([false, false]);
  const [files, setFiles] = useState([]);
  const [multiFiles, setMultiFiles] = useState([]);
  const [step, setStep] = useState(0);
  const [errors, setErrors] = useState({
    beställare: false,
    projektadress: false,
    ställningsentreprenör: false,
    facklängd: false,
    ställningshöjd: false,
    ställningslängd: false,
    bygghiss: false,
    förankringshöjd: false,
    förankringsyta: false,
    inklädnad: false,
    lastklass: false,
    ställningsleverantör: false,
    ställningstyp: false,
    terrängtyp: false,
    typavarbete: false,
    vindhastighet: false,
    väderskyddstak: false,
  });
  const [initValidation, setInitValidation] = useState<{
    [index: number]: boolean;
  }>({
    0: false,
    1: false,
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setForm({ ...form, ...formData });
  }, [formData]);

  useEffect(() => {}, [errors]);

  useEffect(() => {
    if (step === screens.length - 1) {
      document.documentElement.style.setProperty(
        "--padding-bottom-fix",
        `${0}px`
      );
    } else {
      document.documentElement.style.setProperty(
        "--padding-bottom-fix",
        `${24}px`
      );
    }
  }, [step, files, multiFiles, form, errors]);

  const validateFormInputs = (form: any, activeStep: number) => {
    let errorInputsSelects = errors;

    let validSections: Record<number, boolean> = {
      0: false, // projekt info
      1: false, // ställningsinfo
    };
    switch (activeStep) {
      case 0:
        errorInputsSelects.beställare = true;
        errorInputsSelects.projektadress = true;
        errorInputsSelects.ställningsentreprenör = true;

        /** Beställare */
        if (form.beställare.value !== "") {
          errorInputsSelects = {
            ...errorInputsSelects,
            beställare: false,
          };
        }
        /** Projektadress */
        if (form.projektadress.value !== "") {
          errorInputsSelects = {
            ...errorInputsSelects,
            projektadress: false,
          };
        }
        /** Ställningsentreprenör */
        if (form.ställningsentreprenör.value !== "") {
          errorInputsSelects = {
            ...errorInputsSelects,
            ställningsentreprenör: false,
          };
        }
        /** Validate section */
        if (
          !errorInputsSelects.beställare &&
          !errorInputsSelects.projektadress &&
          !errorInputsSelects.ställningsentreprenör
        ) {
          validSections = { ...validSections, 0: true };
        }
        break;
      case 1:
        errorInputsSelects.bygghiss = true;
        errorInputsSelects.facklängd = true;
        errorInputsSelects.förankringshöjd = true;
        errorInputsSelects.förankringsyta = true;
        errorInputsSelects.inklädnad = true;
        errorInputsSelects.lastklass = true;
        errorInputsSelects.ställningshöjd = true;
        errorInputsSelects.ställningslängd = true;
        errorInputsSelects.ställningsleverantör = true;
        errorInputsSelects.ställningstyp = true;
        errorInputsSelects.terrängtyp = true;
        errorInputsSelects.typavarbete = true;
        errorInputsSelects.vindhastighet = true;
        errorInputsSelects.väderskyddstak = true;

        /** bygghiss */
        if (form.bygghiss.value !== "") {
          errorInputsSelects = {
            ...errorInputsSelects,
            bygghiss: false,
          };
        }

        /** facklängd */
        if (form.facklängd.value !== "") {
          errorInputsSelects = {
            ...errorInputsSelects,
            facklängd: false,
          };
        }
        /** förankringshöjd */
        if (form.förankringshöjd.value !== "") {
          errorInputsSelects = {
            ...errorInputsSelects,
            förankringshöjd: false,
          };
        }
        /** förankringsyta */
        if (form.förankringsyta.value !== "") {
          errorInputsSelects = {
            ...errorInputsSelects,
            förankringsyta: false,
          };
        }
        /** inklädnad */
        if (form.inklädnad.value !== "") {
          errorInputsSelects = {
            ...errorInputsSelects,
            inklädnad: false,
          };
        }
        /** lastklass */
        if (form.lastklass.value !== "") {
          errorInputsSelects = {
            ...errorInputsSelects,
            lastklass: false,
          };
        }
        /** ställningshöjd */
        if (form.ställningshöjd.value !== "") {
          errorInputsSelects = {
            ...errorInputsSelects,
            ställningshöjd: false,
          };
        }
        /** ställningslängd */
        if (form.ställningslängd.value !== "") {
          errorInputsSelects = {
            ...errorInputsSelects,
            ställningslängd: false,
          };
        }
        /** ställningsleverantör */
        if (form.ställningsleverantör.value !== "") {
          errorInputsSelects = {
            ...errorInputsSelects,
            ställningsleverantör: false,
          };
        }
        /** ställningstyp */
        if (form.ställningstyp.value !== "") {
          errorInputsSelects = {
            ...errorInputsSelects,
            ställningstyp: false,
          };
        }
        /** terrängtyp */
        if (form.terrängtyp.value !== "") {
          errorInputsSelects = {
            ...errorInputsSelects,
            terrängtyp: false,
          };
        }
        /** typavarbete */
        if (form.typavarbete.value !== "") {
          errorInputsSelects = {
            ...errorInputsSelects,
            typavarbete: false,
          };
        }
        /** vindhastighet */
        if (form.vindhastighet.value !== "") {
          errorInputsSelects = {
            ...errorInputsSelects,
            vindhastighet: false,
          };
        }
        /** väderskyddstak */
        if (form.väderskyddstak.value !== "") {
          errorInputsSelects = {
            ...errorInputsSelects,
            väderskyddstak: false,
          };
        }

        /** Validate section */
        if (
          !errorInputsSelects.bygghiss &&
          !errorInputsSelects.facklängd &&
          !errorInputsSelects.förankringshöjd &&
          !errorInputsSelects.förankringsyta &&
          !errorInputsSelects.inklädnad &&
          !errorInputsSelects.lastklass &&
          !errorInputsSelects.ställningshöjd &&
          !errorInputsSelects.ställningsleverantör &&
          !errorInputsSelects.ställningstyp &&
          !errorInputsSelects.terrängtyp &&
          !errorInputsSelects.typavarbete &&
          !errorInputsSelects.vindhastighet &&
          !errorInputsSelects.väderskyddstak
        ) {
          validSections = { ...validSections, 1: true };
        }
        break;
      default:
        break;
    }
    return { errorInputsSelects, validSections };
  };

  const handleOnUpdatedDragprov = (dragprov: any[]) => {
    setForm((prevState) => ({
      ...prevState,
      dragprov,
    }));
  };

  const handleOnSentInspection = () => {
    setForm({ ...INIT, ...formData });
    setIsOkToProceed([false, false]);
    setStep(0);
    setFiles([]);
    setEditForm({});
    setMultiFiles([]);
    userData.offlinePdf ? history.push("/form") : history.push("/projects");
  };

  const handleOnChangeInputfield = (e?: any, min?: number, max?: number) => {
    let { id, value } = e.target;
    let result = value;
    if (min && value < min) {
      result = min.toString();
    }

    if (max && value > max) {
      result = max.toString();
    }

    if (initValidation[step]) {
      let errorCopy = errors;
      result === ""
        ? setErrors({ ...errorCopy, [id]: true })
        : setErrors({ ...errorCopy, [id]: false });
    }

    setForm((prevState) => ({
      ...prevState,
      [id]: {
        value: result,
        label: prevState[id.toString()].label,
      },
    }));
  };

  const handleOnChangeSelect = (e?: any, opt?: any) => {
    let { value } = e.target;

    if (initValidation[step]) {
      let errorCopy = errors;
      setErrors({ ...errorCopy, [opt]: false });
    }
    setForm((prevState) => ({
      ...prevState,
      [opt]: {
        value: value,
        label: prevState[opt.toString()].label,
      },
    }));
  };

  const handleOnClickInfo = (id: any) => {
    let content = null;
    if (id === "terrängtyp") {
      content = (
        <div>
          <Typography
            style={{ color: "black" }}
            type="body1"
            value="Välj den terrängtyp som passar bäst in på projektets placering."
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <Typography type="h6" value="Typ 0" style={{ flex: 1 }} />
            <Terrangtyp0 width="80%" fill="black" style={{ flex: 1 }} />
            <Typography
              type="body1"
              value="Havs- eller kustområde exponerat för öppet hav."
              style={{ flex: 1, color: "black" }}
            />
          </div>
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
              flexDirection: "column",
            }}
          >
            <Typography type="h6" value="Typ 1" style={{ flex: 1 }} />
            <Terrangtyp1 width="80%" fill="black" style={{ flex: 1 }} />
            <Typography
              type="body1"
              value="Sjö eller plant och horisontellt område med försumbar
              vegetation och utan hinder."
              style={{ flex: 1, color: "black" }}
            />
          </div>
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
              flexDirection: "column",
            }}
          >
            <Typography type="h6" value="Typ 2" style={{ flex: 1 }} />
            <Terrangtyp2 width="80%" fill="black" style={{ flex: 1 }} />
            <Typography
              type="body1"
              value="Område med låg vegetation som gräs och enstaka hinder (träd,
                byggnader) med minsta inbördes avstånd lika med 20 gånger
                hindrens höjd."
              style={{ flex: 1, color: "black" }}
            />
          </div>
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
              flexDirection: "column",
            }}
          >
            <Typography type="h6" value="Typ 3" style={{ flex: 1 }} />
            <Terrangtyp3 width="80%" fill="black" style={{ flex: 1 }} />
            <Typography
              type="body1"
              value="Område täckt med vegetation eller byggnader eller med enstaka
              hinder med största inbördes avstånd lika med 20 gånger
              hindrens höjd (t. ex. byar, förorter, skogsmark)."
              style={{ flex: 1, color: "black" }}
            />
          </div>
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
              flexDirection: "column",
            }}
          >
            <Typography type="h6" value="Typ 4" style={{ flex: 1 }} />
            <Terrangtyp4 width="80%" fill="black" style={{ flex: 1 }} />
            <Typography
              type="body1"
              value="Område där minst 15 % av arean är bebyggd och där byggnadernas
              medelhöjd är > 15 m."
              style={{ flex: 1, color: "black" }}
            />
          </div>
        </div>
      );
    } else {
      let title = "";
      let src = "";
      switch (id) {
        case "förankringshöjd":
          title = `Hur ofta förankras ställningen i höjdled? \n Välj det förankringintervall som stämmer bäst överrens med projektets förankringsintervall. Förankringstätheten påverkar både vilket dragprov förankringarna behöver och tillåtna spirlaster.
            Tätare förankringar gör ställningen styvare och minskar kraften per förankring i dragprovet.`;
          src = anchor;
          break;
        case "facklängd":
          title =
            "Ange största facklängd för ställningen. Detta påverkar vilken kraft förankringarna ska ha.";
          src = dimLength;
          break;
        case "vindhastighet":
          title =
            "Lokalisera projektet på kartan nedan och använd referensvindhastigheten i din inspektion. Finns projektet i exempelvis Stockholm använder du referensvindhastighet 24 m/s.";
          src = WindMap;
          break;
        default:
          break;
      }
      content = (
        <div>
          <Typography style={{ color: "black" }} type="body1" value={title} />
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              backgroundColor: "white",
              marginTop: 20,
            }}
            src={src}
            alt="Logo"
          />
        </div>
      );
    }

    setDialogContent(content);
    setDialogTitle(id);
    setIsDialogOpen(true);
  };

  const handleOnClickNext = () => {
    setInitValidation({ ...initValidation, [step]: true });
    /** Validate here! */
    let { errorInputsSelects, validSections } = validateFormInputs(form, step);
    setErrors(errorInputsSelects);

    if (validSections[step]) {
      setStep(step + 1);
    }
  };

  const handleOnChangeMultiCheckbox = (optionsCopy?: any) => {
    let documentation: any = {};
    optionsCopy.map(
      (x?: any) =>
        (documentation[x.id] = {
          value: x.ispositivechecked,
          label: x.label,
        })
    );

    setForm((prevState) => ({
      ...prevState,
      ...documentation,
    }));
  };

  const getDrawerOptions = () => {
    const { offlinePdf } = userData;

    if (offlinePdf) {
      return [
        {
          value: (
            <Typography
              type="body1"
              value="Hem"
              style={{ color: "#4d4b51", userSelect: "none" }}
            />
          ),
          icon: <HomeIcon style={{ fill: "#4d4b51" }} />,
          onClick: () => {
            setOpen(false);
          },
        },
        {
          value: (
            <Typography
              type="body1"
              value="Nytt projekt"
              style={{ color: "#4d4b51", userSelect: "none" }}
            />
          ),
          icon: <NoteAddIcon style={{ fill: "#4d4b51" }} />,
          onClick: () => {
            setOpen(false);
          },
        },
      ];
    } else {
      return [
        {
          value: (
            <Typography
              type="body1"
              value="Hem"
              style={{ color: "#4d4b51", userSelect: "none" }}
            />
          ),
          icon: <HomeIcon style={{ fill: "#4d4b51" }} />,
          onClick: () => {
            history.push("/projects");
            setEditForm({});
          },
        },
        {
          value: (
            <Typography
              type="body1"
              value="Nytt projekt"
              style={{ color: "#4d4b51", userSelect: "none" }}
            />
          ),
          icon: <NoteAddIcon style={{ fill: "#4d4b51" }} />,
          onClick: () => {
            setStep(0);
            setOpen(false);
            setForm({ ...INIT });
            setEditForm({});
          },
        },
        {
          value: (
            <Typography
              type="body1"
              value="Mina projekt"
              style={{ color: "#4d4b51", userSelect: "none" }}
            />
          ),
          icon: <AssignmentIcon style={{ fill: "#4d4b51" }} />,
          onClick: () => {
            history.push("/projects");
            setEditForm({});
          },
        },
      ];
    }
  };

  const screens = [
    {
      header:
        Object.keys(formData).length === 0
          ? "Nytt projekt"
          : "Redigera projekt",
      component: (
        <NewProject
          form={form}
          handleOnChangeInputfield={handleOnChangeInputfield}
          errors={errors}
        />
      ),
    },
    {
      header: "Ställningskontroll",
      component: (
        <Control
          form={form}
          handleOnChangeInputfield={handleOnChangeInputfield}
          handleOnChangeSelect={handleOnChangeSelect}
          handleOnClickInfo={handleOnClickInfo}
          errors={errors}
        />
      ),
    },
    {
      header: "Dokumentation",
      component: (
        <Documentation
          form={form}
          handleOnClickBack={() => {
            setStep(step - 1);
          }}
          files={files}
          setFiles={setFiles}
          multiFiles={multiFiles}
          setMultiFiles={setMultiFiles}
          isOffline={userData.offlinePdf}
          handleOnChangeInputfield={handleOnChangeInputfield}
          handleOnChangeMultiCheckbox={handleOnChangeMultiCheckbox}
          handleOnUpdatedDragprov={handleOnUpdatedDragprov}
          handleOnClickInfo={handleOnClickInfo}
          handleOnSentInspection={handleOnSentInspection}
        />
      ),
    },
  ];

  return (
    <FullscreenContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <div className="FormScreen-Top-NavbarShadow">
          <Dialog
            open={isDialogOpen}
            handleOnClickCancel={() => {
              setIsDialogOpen(false);
            }}
            title={dialogTitle}
            text={dialogContent}
            content={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                  marginTop: 6,
                  marginBottom: 8,
                }}
              >
                <Button
                  onClick={() => setIsDialogOpen(false)}
                  title={"Ok"}
                  style={{ marginLeft: "1%", minWidth: "45%" }}
                />
              </div>
            }
          />
          <div className="PaddingContainer">
            <NavBarContainer
              header={`${step + 1}. ${screens[step].header}`}
              onClickMenu={() => setOpen(true)}
            />
            <Drawer
              user={userData.firstName}
              company={userData.company}
              options={getDrawerOptions()}
              open={open}
              onCloseDrawer={() => setOpen(false)}
              onClickShowProfile={() => {
                history.push("/profile");
              }}
            />
          </div>
          <div
            className={clsx("FormScreen-StepperContainer", "PaddingContainer")}
          >
            <Stepper steps={3} activeStep={step} />
          </div>
        </div>
        <div className="FormScreen-ContentContainer">
          {screens[step].component}
        </div>
        <div className="FormScreen-Bottom-NavbarShadow">
          <div
            className={clsx("FormScreen-ButtonContainer", "PaddingContainer")}
            style={{
              display: step === screens.length - 1 ? "none" : "flex",
              justifyContent: step === 0 ? "flex-end" : "space-between",
            }}
          >
            <Button
              style={{
                minWidth: "45%",
                borderColor: "white",
                color: "white",
                display: step === 0 ? "none" : "inline",
              }}
              title="Tillbaka"
              type="secondary"
              onClick={() => {
                setStep(step - 1);
              }}
            />
            <Button
              style={{ minWidth: "45%" }}
              title="Nästa"
              type="primary"
              onClick={handleOnClickNext}
            />
          </div>
        </div>
      </div>
    </FullscreenContainer>
  );
};

export default FormScreen;
