const OPTIONS = {
  förankringshöjd: {
    id: "förankringshöjd",
    value: [
      { value: "var annan", label: "Varannan höjdmeter" },
      { value: "var tredje", label: "Var tredje höjdmeter" },
      { value: "var fjärde", label: "Var fjärde höjdmeter" },
      { value: "fristående", label: "Inga förankringar, fristående ställning" },
    ],
  },
  inklädnad: {
    id: "inklädnad",
    value: [
      { value: "helt inklädd, väv", label: "Helt inklädd, väv" },
      { value: "delvis inklädd, väv", label: "Delvis inklädd, väv" },
      { value: "helt inklädd, duk", label: "Helt inklädd, duk" },
      { value: "delvis inklädd, duk", label: "Delvis inklädd, duk" },
      { value: "ej inklädd", label: "Ej inklädd" },
    ],
  },
  vindhastighet: {
    id: "vindhastighet",
    value: [
      { value: 21, label: "21 m/s" },
      { value: 22, label: "22 m/s" },
      { value: 23, label: "23 m/s" },
      { value: 24, label: "24 m/s" },
      { value: 25, label: "25 m/s" },
      { value: 26, label: "26 m/s" },
    ],
  },
  terrängtyp: {
    id: "terrängtyp",
    value: [
      { value: 0, label: "Terrängtyp 0" },
      { value: 1, label: "Terrängtyp 1" },
      { value: 2, label: "Terrängtyp 2" },
      { value: 3, label: "Terrängtyp 3" },
      { value: 4, label: "Terrängtyp 4" },
    ],
  },
  ställningstyp: {
    id: "ställningstyp",
    value: [
      { value: "prefab", label: "Prefabricerad ställning" },
      { value: "rör", label: "Rör och koppling" },
      { value: "trä", label: "Träställning" },
    ],
  },
  ställningsleverantör: {
    id: "ställningsleverantör",
    value: [
      { value: "layher", label: "Layher" },
      { value: "haki", label: "Haki" },
      { value: "pluseight", label: "Pluseight" },
      { value: "altrad", label: "Altrad" },
      { value: "monzon", label: "Monzon" },
      { value: "trebex", label: "Trebex" },
      { value: "blandat", label: "Blandat" },
      { value: "övrigt", label: "Övrigt" },
    ],
  },
  lastklass: {
    id: "lastklass",
    value: [
      { value: 1, label: "1 (0.75 kN/m2)" },
      { value: 2, label: "2 (1.5 kN/m2)" },
      { value: 3, label: "3 (2 kN/m2)" },
      { value: 4, label: "4 (3 kN/m2)" },
      { value: 5, label: "5 (4.5 kN/m2)" },
      { value: 6, label: "6 (6 kN/m2)" },
    ],
  },
  väderskyddstak: {
    id: "väderskyddstak",
    value: [
      { value: "nej", label: "Nej" },
      { value: "layher", label: "Ja, Layher" },
      { value: "pluseight", label: "Ja, Pluseight" },
      { value: "haki", label: "Ja, Haki" },
      { value: "gibson", label: "Ja, Gibson (Hallbyggarna Jonsered)" },
      { value: "övrig", label: "Ja, Övrig" },
    ],
  },
  bygghiss: {
    id: "bygghiss",
    value: [
      { value: "ställning", label: "Ja, förankrad i ställning" },
      { value: "fasad", label: "Ja, förankrad i fasad" },
      { value: "nej", label: "Nej" },
    ],
  },
  typavarbete: {
    id: "typavarbete",
    value: [
      { value: "puts/murning", label: "Puts/murning", loadClass: 5 },
      {
        value: "tak",
        label: "Takarbete/plåtslagning",
        loadClass: 3,
      },
      { value: "målning", label: "Målning", loadClass: 3 },
      { value: "övrigt", label: "Övrigt", loadClass: 0 },
    ],
  },
  förankringsyta: {
    id: "förankringsyta",
    value: [
      { value: "betong", label: "Betong", maxForce: 13 },
      { value: "tegelsten", label: "Tegelsten", maxForce: 10 },
      { value: "trä", label: "Trä", maxForce: 6 },
      { value: "fog", label: "Fog", maxForce: 3 },
      { value: "annat", label: "Annat", maxForce: 0 },
    ],
  },
  facklängd: {
    id: "facklängd",
    value: [
      { value: "0.5", label: "0.5 m" },
      { value: "1.0", label: "1.0 m" },
      { value: "1.5", label: "1.5 m" },
      { value: "2.0", label: "2.0 m" },
      { value: "2.5", label: "2.5 m" },
      { value: "3.0", label: "3.0 m" },
      { value: "3.5", label: "3.5 m" },
      { value: "4.0", label: "4.0 m" },
      { value: "4.5", label: "4.5 m" },
      { value: "5.0", label: "5.0 m" },
    ],
  },
};

export default OPTIONS;
