import React, { FC } from "react";
import "./Stepper.css";

import MobileStepper from "@material-ui/core/MobileStepper";

type StepperProps = {
  steps: number;
  activeStep?: number;
};
const Stepper: FC<StepperProps> = ({ steps = 3, activeStep = 0 }) => {
  return (
    <MobileStepper
      variant="dots"
      steps={steps}
      position="static"
      activeStep={activeStep}
      backButton={null}
      nextButton={null}
    />
  );
};

export default Stepper;
