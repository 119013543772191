const INIT = {
  edit: false,
  projektadress: { value: "", label: "Projektadress" },
  ställningsentreprenör: {
    value: "",
    label: "Ställningsentreprenör",
  },
  beställare: { value: "", label: "Beställare" },
  ansvarig: { value: "", label: "BAS-U" },
  skyddsombud: { value: "", label: "Skyddsombud" },
  dimensioneringshandlingar: {
    value: false,
    label: "Dimensioneringshandlingar / Typfallshandlingar",
  },
  uppförandeplan: {
    value: false,
    label: "Plan för uppförande, använding och nedmontering",
  },
  monteringsinstruktioner: {
    value: false,
    label: "Monteringsinstruktioner",
  },
  ställningskontroll: {
    value: false,
    label: "Kontroll av ställning - Grön skylt",
  },
  riskbedömning: {
    value: false,
    label: "Riskbedömning",
  },
  ställningshöjd: {
    value: "",
    label: "Ställningshöjd",
  },
  ställningslängd: {
    value: "",
    label: "Ställningslängd",
  },
  facklängd: { value: "", label: "Facklängd" },
  förankringshöjd: {
    value: "var annan",
    label: "Förankringshöjd",
  },
  inklädnad: { value: "", label: "Inklädnad" },
  terrängtyp: { value: "", label: "Terrängtyp" },
  vindhastighet: { value: "", label: "Vindhastighet" },
  ställningstyp: { value: "", label: "Ställningstyp" },
  ställningsleverantör: {
    value: "",
    label: "Ställningsleverantör",
  },
  lastklass: { value: "", label: "Lastklass" },
  väderskyddstak: {
    value: "",
    label: "Väderskyddstak",
  },
  bygghiss: { value: "", label: "Bygghiss" },
  underlag: { value: "", label: "Underlag" },
  typavarbete: { value: "", label: "Typ av arbete" },
  förankringsyta: {
    value: "",
    label: "Förankringsyta",
  },
  spirorcentrerade: {
    value: false,
    label: "Spiror/fötter vilar på underpallningen och är centrerade",
  },
  lutandeunderlag: {
    value: false,
    label: "Lutar underlaget?",
  },
  killutandeunderlag: {
    value: false,
    label: "Kil används vid lutande underlag",
  },
  underpallning: {
    value: false,
    label: "Korrekt dimensionerad underpallning",
  },
  godkändplacering: {
    value: false,
    label: "Förankringar är godkänt placerade",
  },
  vförankringar: {
    value: false,
    label: "V-förankring används vart femte fack",
  },
  provdragningsprotokoll: {
    value: false,
    label: "Provdragningsprotokoll finns på plats",
  },
  korrektantal: {
    value: false,
    label: "Korrekt antal",
  },
  korrektplacering: {
    value: false,
    label: "Diagonalstagen är placerade korrekt",
  },
  avståndtillvägg: {
    value: false,
    label: "Ställningens avstånd från vägg är minimerat",
  },
  fulltintäckt: {
    value: false,
    label: "Samtliga arbetsplan är fullt intäckta, kompletta och fastsatta",
  },
  komponenterfastsatta: {
    value: false,
    label: "Komponenter fastsatta",
  },
  snubbelrisk: {
    value: false,
    label: "Är arbetsplanet fritt från utstickande föremål?",
  },
  ställningsplank: {
    value: false,
    label: "Ställningsplanken är förbundna (okade) med varandra",
  },
  oberoendetillträde: {
    value: false,
    label: "Korrekt antal oberoende tillträdelseleder",
  },
  trapplöp: {
    value: false,
    label: "Trapplöp",
  },
  trapptorn: {
    value: false,
    label: "Trapptorn",
  },
  faststege: {
    value: false,
    label: "Fast stege",
  },
  tillräckligräckeshöjd: {
    value: false,
    label: "konstruktionen har tillräcklig räckeshöjd",
  },
  överledare: {
    value: false,
    label: "Arbetsplan har överledare, mellanledare och fotlist",
  },
  förtätning: {
    value: false,
    label: "Förtätning med korrekt skyddsklass",
  },
  fallandeföremål: {
    value: false,
    label: "Finns risk för fallande föremål?",
  },
  skyddstak: {
    value: false,
    label: "Skyddstak används",
  },
  trafik: {
    value: false,
    label: "Förekommer trafik runt arbetet?",
  },
  påkörningsskydd: {
    value: false,
    label: "Påkörningsskydd används",
  },
  fleraställningsnivåer: {
    value: false,
    label: "Belastas flera ställningsnivåer samtidigt?",
  },
  dimfleraställningsnivåer: {
    value: false,
    label: "Dimensionerat för belastning av flera ställningsnivåer",
  },
  tungutrustning: {
    value: false,
    label: "Finns material/tung utrustning på ställningen?",
  },
  dimtungutrustning: {
    value: false,
    label: "Korrekt dimensionering för tung utrustning",
  },
  lastningmedlyftmaskin: {
    value: false,
    label: "Pågår lastning av material med lyftmaskin?",
  },
  godkändlastningmedlyftmaskin: {
    value: false,
    label: "Endast personal som lyfter av/på material vistas på ställningen",
  },
  pågåendeuppförande: {
    value: false,
    label: "Pågår uppförande av ställning?",
  },
  ställningstillträdespärrat: {
    value: false,
    label: "Ställningstillträde spärrat för obehöriga",
  },
  arbetsområdespärrat: {
    value: false,
    label: "Arbetsområdet spärrat för obehöriga",
  },
  särskildarisker: {
    value: false,
    label: "Skydd mot särskilda risker (trafik, vatten, elledningar m.m.)",
  },
  skyddsutrustning: {
    value: false,
    label: "Skyddsutrustning används",
  },
  skyddmotfall: {
    value: false,
    label: "Skydd mot fall",
  },
  utbildningsintyg: {
    value: false,
    label: "Montören har giltigt utbildningsintyg på plats",
  },
  rättutbildning: {
    value: false,
    label: "Montören har korrekt utbildning för aktuell montering",
  },
  lärling: {
    value: false,
    label: "Deltar lärling i arbete?",
  },
  lärlinggodkänd: {
    value: false,
    label: "Lärling godkänd",
  },
  ställninggodkänd: {
    value: false,
    label: "Ställning godkänd",
  },
  notes: {
    value: "",
    label: "Anteckningar",
  },
  notes1: {
    value: "",
    label: "Anteckningar",
  },
  notes2: {
    value: "",
    label: "Anteckningar",
  },
  notes3: {
    value: "",
    label: "Anteckningar",
  },
  notes4: {
    value: "",
    label: "Anteckningar",
  },
  notes5: {
    value: "",
    label: "Anteckningar",
  },
  notes6: {
    value: "",
    label: "Anteckningar",
  },
  notes7: {
    value: "",
    label: "Anteckningar",
  },
  notes8: {
    value: "",
    label: "Anteckningar",
  },
  notes9: {
    value: "",
    label: "Anteckningar",
  },
};

export default INIT;
