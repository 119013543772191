import React, { FC, Fragment, useEffect, useState } from "react";

import FormScreen from "../../screens/FormScreen";
import LoginScreen from "../../screens/LoginScreen";
import ProfileScreen from "../../screens/ProfileScreen";
import ProjectScreen from "../../screens/ProjectScreen";
import ProtectedRoute from "../../components/ProtectedRoute";

import {
  authStateChanged,
  getUserData,
  getUserClaims,
  setFirebaseUserProperties,
} from "../../services/firebase";
import { useHistory, Switch, Route } from "react-router-dom";

type RouteManagerProps = {};
const RouteManager: FC<RouteManagerProps> = () => {
  let history = useHistory();
  const [editForm, setEditForm] = useState<{ [index: string]: any }>({});
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState<any>({
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    phone: "",
    role: "",
    offlinePdf: false,
  });

  useEffect(() => {
    authStateChanged(handleOnAuthStateChanged);
  }, []);

  let handleOnAuthStateChanged = (user?: any) => {
    setUser(user);

    getUserClaims()?.then((claims) => {
      const { store_pdf, store_form } = claims;

      if (store_pdf === false || store_form === false) {
        setUserData((prevState: any) => {
          return {
            ...prevState,
            offlinePdf: true,
          };
        });
      }
    });

    if (user) {
      history.push("/form");
    }

    getUserData()?.then((userDoc) =>
      userDoc.forEach((userSnap) => {
        const userData = userSnap.data();
        const nameSplit = userData.name.split(" ");
        const firstName = nameSplit.length > 0 ? nameSplit[0] : "";
        const lastName = nameSplit.length > 1 ? nameSplit[1] : "";

        setFirebaseUserProperties(userData.company);
        setUserData((prevState: any) => {
          return {
            ...prevState,
            ...userData,
            firstName,
            lastName,
          };
        });
      })
    );
  };

  return (
    <Switch>
      <Route exact path="/">
        <LoginScreen />
      </Route>
      <ProtectedRoute
        exact={true}
        path="/form"
        user={user}
        component={
          <FormScreen
            userData={userData}
            formData={editForm}
            setEditForm={setEditForm}
          />
        }
      />
      <ProtectedRoute
        exact={true}
        path="/profile"
        user={user}
        component={<ProfileScreen userData={userData} />}
      />
      {!userData.offlinePdf ? (
        <ProtectedRoute
          exact={true}
          path="/projects"
          user={user}
          component={
            <ProjectScreen userData={userData} setEditForm={setEditForm} />
          }
        />
      ) : null}
    </Switch>
  );
};

export default RouteManager;
