import React, { FC, useEffect } from "react";
import "./Table.css";

import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TableMaterial from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import AssignmentIcon from "@material-ui/icons/Assignment";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { ContextMenuTrigger } from "react-contextmenu";
import { showMenu, hideMenu } from "react-contextmenu/modules/actions";

import Contextmenu from "../../components/Contextmenu";
import "./Table.css";

type TableProps = {
  rows: Array<any>;
  onClickContextmenu?: any;
  onDoubleClickRow?: any;
};

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
    cursor: "pointer",
  },
  icon: {
    color: "#7c8b98",
  },
  iconArrow: {
    color: "white",
  },
});

function Row(props: {
  row: any;
  onClickRow?: any;
  onClickContextmenu?: any;
  selectedId?: any;
  onDoubleClickRow?: any;
}) {
  const { row } = props;
  function collect() {
    return { docId: row.docId, timestamp: row.timestamp_iso };
  }

  const onClickMore: any = async (e: any, row: any) => {
    await hideMenu();
    showMenu({
      position: { x: e.pageX, y: e.pageY },
      id: "context-menu",
      data: collect(),
    });
  };

  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  return (
    <React.Fragment>
      <ContextMenuTrigger
        collect={collect}
        renderTag="tr"
        holdToDisplay={300}
        id="context-menu"
        attributes={
          props.selectedId === row.docId
            ? { style: { backgroundColor: "#263341" } }
            : {}
        }
      >
        <TableCell
          className="tableBodyCell"
          onClick={(e: any) => props.onClickRow(e, row)}
          onDoubleClick={(e: any) =>
            props.onDoubleClickRow(row.timestamp_iso, row.docId)
          }
        >
          {row.namn}
        </TableCell>
        <TableCell
          className="tableBodyCell"
          onClick={(e: any) => props.onClickRow(e, row)}
          onDoubleClick={(e: any) =>
            props.onDoubleClickRow(row.timestamp_iso, row.docId)
          }
        >
          {row.ägare}
        </TableCell>
        <TableCell
          className="tableBodyCell"
          onClick={(e: any) => props.onClickRow(e, row)}
          onDoubleClick={(e: any) =>
            props.onDoubleClickRow(row.timestamp_iso, row.docId)
          }
        >
          {row.skapad}
        </TableCell>
        <TableCell className="tableBodyCell">
          <IconButton onClick={(e) => onClickMore(e, row)}>
            <MoreHorizIcon
              style={{
                marginLeft: "0.5em",
                color: "white",
              }}
            />
          </IconButton>
        </TableCell>
      </ContextMenuTrigger>
      <TableRow style={{ cursor: "pointer" }}>
        <TableCell style={{ padding: 0 }} colSpan={4}>
          {row.subRow ? (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <TableMaterial size="small">
                <TableBody>
                  {row.subRow.map((sub: any, idx: number) => (
                    <TableRow key={idx} hover>
                      <TableCell
                        className="tableHeaderCell"
                        style={{
                          wordBreak: "break-word",
                          borderStyle: "none",
                          padding: 16,
                          userSelect: "none",
                        }}
                      >
                        {sub.namn}
                      </TableCell>
                      <TableCell
                        className="tableHeaderCell"
                        style={{
                          wordBreak: "break-word",
                          borderStyle: "none",
                          padding: 16,
                          userSelect: "none",
                        }}
                      >
                        {sub.ägare}
                      </TableCell>
                      <TableCell
                        className="tableHeaderCell"
                        style={{
                          borderStyle: "none",
                          padding: 16,
                          userSelect: "none",
                        }}
                      >
                        {sub.skapad}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </TableMaterial>
            </Collapse>
          ) : null}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const Table: FC<TableProps> = ({
  rows,
  onClickContextmenu,
  onDoubleClickRow,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedId, setSelectedId] = React.useState(null);

  useEffect(() => {}, [rows]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onClickRow: any = async (e: any, row: any) => {
    await hideMenu();
    setSelectedId(row.docId);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper elevation={0}>
      <TableContainer>
        <TableMaterial>
          <TableHead>
            <TableRow>
              {/* <TableCell /> */}
              <TableCell className="tableHeaderCell">Namn</TableCell>
              <TableCell className="tableHeaderCell">Ägare</TableCell>
              <TableCell className="tableHeaderCell">Skapad</TableCell>
              <TableCell className="tableHeaderCell"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ userSelect: "none", WebkitUserSelect: "none" }}>
            {rows.map((row, idx) => (
              <Row
                key={idx}
                row={row}
                onClickRow={onClickRow}
                selectedId={selectedId}
                onDoubleClickRow={onDoubleClickRow}
              />
            ))}
          </TableBody>
        </TableMaterial>
        <Contextmenu
          contextId="context-menu"
          onClick={onClickContextmenu}
          onShowContextmenu={setSelectedId}
        />
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={"Antal rader"}
        backIconButtonProps={{ style: { color: "white" } }}
        nextIconButtonProps={{ style: { color: "white" } }}
      /> */}
    </Paper>
  );
};

export default Table;
