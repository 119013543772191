import React, { FC, useMemo } from "react";

import {
  createMuiTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import "typeface-roboto";

import "./ThemeManager.css";

type ThemeManagerProps = {
  children?: React.ReactNode;
  theme?: object;
};

const ThemeManager: FC<ThemeManagerProps> = (props) => {
  var theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          primary: {
            main: "#00970e", // Emerald green
            contrastText: "#FFFFFF",
          },
          secondary: {
            main: "#15202b", // Dark
            contrastText: "#7c8b98", // Blue grey
          },
        },
        overrides: {
          MuiButton: {
            root: {
              borderRadius: 32,
              height: "100%",
              padding: 5,
              paddingTop: 10,
              paddingBottom: 10,
              fontFamily: "Roboto",
              borderStyle: "solid",
              borderWidth: 2,
              borderColor: "#7c8b98",
            },
          },
          MuiMobileStepper: {
            root: { backgroundColor: "#15202b" },
            dotActive: { backgroundColor: "#FFFFFF" },
            dot: {
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#FFFFFF",
              marginLeft: 12,
            },
          },

          MuiInputBase: {
            root: {
              borderRadius: 32,
            },
            input: {
              backgroundColor: "#263341", // darkTwo
              color: "#FFFFFF",
              borderRadius: 32,
            },
            fullWidth: {
              borderRadius: 32,
            },
          },
          MuiFilledInput: {
            root: {
              borderTopRightRadius: 32,
              borderTopLeftRadius: 32,
            },
          },
          MuiInputLabel: { root: { color: "#7c8b98" } },
          MuiTable: {
            root: {
              backgroundColor: "#15202b",
            },
          },
          MuiDialogTitle: {
            root: {
              color: "black",
              backgroundColor: "#FFFFFF",
            },
          },
          MuiTableCell: {
            head: {
              color: "#FFFFFF",
              borderColor: "#7c8b98",
            },
            body: {
              color: "#FFFFFF",
              borderColor: "#485661",
            },
          },
          MuiTablePagination: {
            root: {
              backgroundColor: "#15202b",
            },
            caption: {
              color: "#FFFFFF",
            },
            selectIcon: {
              color: "#FFFFFF",
            },
          },
          MuiSelect: {
            iconFilled: { color: "#7c8b98" },
            filled: { borderRadius: 32 },
          },
          MuiTypography: {
            h1: { fontFamily: "Ramaraja", color: "white" },
            h2: { fontFamily: "Ramaraja", color: "white" },
            h3: { fontFamily: "Roboto", color: "white" },
            h4: { fontFamily: "Roboto", color: "white", fontWeight: "bold" },
            h5: { fontFamily: "Roboto", color: "white" },
            h6: { fontFamily: "Roboto", color: "inherit" },
            body1: { fontFamily: "Roboto", color: "white" },
            body2: { fontFamily: "Roboto", color: "black" },
          },
        },
        // @ts-ignore
        custom: {
          palette: {
            notselected: {
              color: "white",
              backgroundColor: "#273341",
            },
          },
          overrides: {
            ButtonGroup: {
              borderRadius: 32,
              backgroundColor: "#273341",
            },
            ButtonGroupButton: {
              borderRadius: 32,
              padding: 5,
              paddingTop: 10,
              paddingBottom: 10,
              fontFamily: "Roboto",
            },
          },
        },
      }),
    [props.theme]
  );

  theme = responsiveFontSizes(theme);

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default ThemeManager;
