let SPETSFAKTOR = 3;
let TURBULENSFAKTOR = 1;
let TOPOGRAFIFAKTOR = 1;
let RÅHETSLÄNGDPARALLELL = 0.05;
let LUFTDENSITET = 1.25;
let RIKTNINGSFAKTOR = 1;
let ÅRSTIDSFAKTOR = 1;
let DRAGPROVSAVSTÅND = 2;
let DECIMALS = 2;

const dragProv = (
  ställningshöjd: number,
  förankringshöjd: number,
  inklädnad: string,
  facklängd: number,
  terrängtyp: number,
  vindhastighet: number,
  leverantör: string
) => {
  let out: any = [];

  let init;
  if (förankringshöjd === 0) return out; // fristående ställning
  for (let i = ställningshöjd; i >= DRAGPROVSAVSTÅND; i = i - förankringshöjd) {
    init = Math.max(
      karaktäristisktHastighetstryck(i, terrängtyp, vindhastighet),
      qMin(terrängtyp, vindhastighet)
    );

    let kraft = round(
      förankringsArea(inklädnad, facklängd, förankringshöjd) *
        lägesFaktorCs(inklädnad) *
        1.3 *
        0.7 *
        init *
        1.5 +
        0.01
    ); // Kompensationsfaktor för något outgrundligt fel

    let maxSupplierForce = 0;
    switch (leverantör) {
      case "layher":
        maxSupplierForce = 4;
        break;
      case "pluseight":
        maxSupplierForce = 3.9;
        break;
      case "haki":
        maxSupplierForce = 6.5;
        break;
      case "altrad":
        maxSupplierForce = 3.1;
        break;
      case "monzon":
        maxSupplierForce = 4.8;
        break;
      case "trebex":
        maxSupplierForce = 3.7;
        break;
      case "blandat":
        maxSupplierForce = 6.5 * 1.1;
        break;
      default:
        break;
      case "övrigt":
        maxSupplierForce = 6.5;
    }

    kraft = Math.max(maxSupplierForce, kraft);

    out.push({ pos: i, kraft });
  }
  return out;
};

const lägesFaktorCs = (inklädnad: string) => {
  let out;

  if (inklädnad !== "ej inklädd") {
    out = 1;
  } else {
    out = 0.75;
  }

  return out;
};

const förankringsArea = (
  inklädnad: string,
  facklängd: number,
  förankringshöjd: number
) => {
  let out;

  if (inklädnad !== "ej inklädd") {
    out = facklängd * förankringshöjd;
  } else {
    out = 0.2 * förankringshöjd * facklängd + 0.11;
  }
  return out;
};

const karaktäristisktHastighetstryck = (
  ställningshöjd: number,
  terrängtyp: number,
  vindhastighet: number
) => {
  let blob1 =
    1 + 2 * SPETSFAKTOR * turbulensIntensitet(ställningshöjd, terrängtyp);
  blob1 = isNaN(blob1) ? 0 : blob1;

  let blob2 = Math.log(ställningshöjd / råhetsLängd(terrängtyp));
  let blob3 = terrängFaktor(terrängtyp) * TOPOGRAFIFAKTOR * blob2;
  let blob4 = Math.pow(blob3, 2) * referensHastighetsTryck(vindhastighet);
  let final = (blob1 * blob4) / 1000;

  return isNaN(final) ? 0 : final;
};

const qMin = (terrängtyp: number, vindhastighet: number) => {
  let blob1 = Math.log(minimumHöjd(terrängtyp) / råhetsLängd(terrängtyp));
  blob1 = isNaN(blob1) ? 0 : blob1;
  let blob2 = TURBULENSFAKTOR / (TOPOGRAFIFAKTOR * blob1);
  let blob3 = 1 + 2 * SPETSFAKTOR * blob2;
  let blob4 =
    Math.pow(blob1 * TOPOGRAFIFAKTOR * terrängFaktor(terrängtyp), 2) *
    referensHastighetsTryck(vindhastighet);
  let final = (blob3 * blob4) / 1000;

  return isNaN(final) ? 0 : final;
};

const turbulensIntensitet = (ställningshöjd: number, terrängtyp: number) => {
  return (
    TURBULENSFAKTOR /
    (TOPOGRAFIFAKTOR * Math.log(ställningshöjd / råhetsLängd(terrängtyp)))
  );
};

const råhetsLängd = (terrängtyp: number) => {
  let längd;

  switch (terrängtyp) {
    case 0:
      längd = 0.003;
      break;
    case 1:
      längd = 0.01;
      break;
    case 2:
      längd = 0.05;
      break;
    case 3:
      längd = 0.3;
      break;
    case 4:
      längd = 1;
      break;
    default:
      längd = 0;
      break;
  }

  return längd;
};

const terrängFaktor = (terrängtyp: number) => {
  return 0.19 * Math.pow(råhetsLängd(terrängtyp) / RÅHETSLÄNGDPARALLELL, 0.07);
};

const referensHastighetsTryck = (vindhastighet: number) => {
  return (LUFTDENSITET * Math.pow(referensVindHastighet(vindhastighet), 2)) / 2;
};

const referensVindHastighet = (vindhastighet: number) => {
  return ÅRSTIDSFAKTOR * RIKTNINGSFAKTOR * vindhastighet;
};

const minimumHöjd = (terrängtyp: number) => {
  let höjd;
  switch (terrängtyp) {
    case 0:
      höjd = 1;
      break;
    case 1:
      höjd = 2;
      break;
    case 2:
      höjd = 2;
      break;
    case 3:
      höjd = 5;
      break;
    case 4:
      höjd = 10;
      break;
    default:
      höjd = 0;
      break;
  }

  return höjd;
};

const round = (num: number) => {
  let amount;
  let res = num;
  amount = Math.pow(10, DECIMALS);
  res = Math.floor(num * amount) / amount;

  return res;
};

export { dragProv };
