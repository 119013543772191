import underpallning from "../../assets/images/underpallning.png";
import diagonalstag from "../../assets/images/diagonalstag.png";
import platformLock from "../../assets/images/platformLock.png";
import stairTower from "../../assets/images/stairTower.png";
import fullDeck from "../../assets/images/fullDeck.png";
import railing from "../../assets/images/railing.png";
import anchor from "../../assets/images/anchor.png";
import loading from "../../assets/images/loading.png";
import kil from "../../assets/images/kil.png";
import vAnchor from "../../assets/images/vAnchor.png";
import table from "../../assets/images/table.png";
import greenSign from "../../assets/images/greenSign.png";

const DEFAULT_FALSE = {
  ispositivechecked: false,
  isnegativechecked: true,
};

const OPTIONS: any = {
  options1: {
    name: "1. Underlag, placering och utformning",
    value: [
      {
        idx: 1,
        id: "spirorcentrerade",
        label: "Spiror/fötter vilar på underpallningen och är centrerade",
        info: `Spirornas fötter ska vila på underpallningen och vara centrerade i mitten som i bilden nedan.`,
        title: "Godkänd placering",
        images: [underpallning],
        ...DEFAULT_FALSE,
      },
      {
        idx: 2,
        id: "underpallning",
        label: "Korrekt dimensionerad underpallning",
        title: "Underpallning",
        info: `Underpallningen ska vara korrekt dimensionerad utefter kraften som inverkar på spirornas fötter och underlagets bärförmåga. 
        <br> Generellt sätt är underpallningens bredd 20 cm och dess längd kan beräknas fram enligt följande formel: <br><br>
        <b>(Spirans kraft / bärförmågan av marken x 0.2) x 100 </b><br>
        T.ex. vid 21kN kraft ock 500 kN ges en rekommenderad längd av: (21/300 x 0.2) x 100 = 35 cm
        <br><br>
        Följande värden för olika underlags bärförmågor kan användas som riktvärden men är inte tillförlitliga. <br>
        <ul>
        <li>Betong - 500 kN</li>
        <li>Sten - 500 kN</li>
        <li>Grus - 500 kN</li>
        <li>Asfalt - 500 kN</li>
        <li>Packad grovsand - 350 kN</li>
        <li>Trottoar 300 kN</li>
        <li>Packad finsand - 250 kN</li>
        <li>Gräsmatta - 125 kN</li>
        <li>Lös sand - 125 kN</li> 
        <li>Lera - 80 kN</li>
        </ul>
        `,
        ...DEFAULT_FALSE,
      },
      {
        idx: 3,
        id: "lutandeunderlag",
        label: "Lutar underlaget?",
        ...DEFAULT_FALSE,
      },
      {
        idx: 4,
        id: "killutandeunderlag",
        label: "Kil används vid lutande underlag",
        info: `Kil ska användas vid lutande underlag för att motverka glid.`,
        title: "Kil",
        images: [kil],
        ...DEFAULT_FALSE,
      },
    ],
  },
  options2: {
    name: "2. Förankringar",
    value: [
      {
        idx: 0,
        id: "godkändplacering",
        label: "Förankringar är godkänt placerade",
        info: `En godkänd placering av förankringar avser att förankringarna kontinuerligt finns varje angiven höjdmeter.<br>
        Förankringar ska finnas vid varje innespira, vid varje angiven höjdmeter. <br> Exempel för inplastade ställningar:
        Förankring skall finnas vid varje innespira, var annan höjdmeter.`,
        title: "Godkänd placering",
        images: [anchor],
        ...DEFAULT_FALSE,
      },
      {
        idx: 1,
        id: "vförankringar",
        label: "V-förankring används vart femte fack",
        info: `V-förankring (även kallad X-förankring) skall finnas vart femte fack.`,
        title: "V-förankring",
        images: [vAnchor],
        ...DEFAULT_FALSE,
      },
      {
        idx: 2,
        id: "provdragningsprotokoll",
        label: "Provdragningsprotokoll finns på plats",
        info: `Allmänna råd: Om man är osäker på förankringarnas lastupptagande
        förmåga kan förankringarna behöva provdras.<br><br>
        Det är viktigt att vara uppmärksam på risken att rullställningar kan
        börja glida, i synnerhet vid lutande eller glatt underlag, eller om friktionen är liten mellan rullställningens hjul och underlaget (AFS2013:4 §51).`,
        title: "Provdragningsprotokoll",
        ...DEFAULT_FALSE,
      },
    ],
  },
  options3: {
    name: "3. Diagonalstagning",
    value: [
      {
        idx: 0,
        id: "korrektantal",
        label: "Korrekt antal",
        info: `Ett förenklat sätt att beräkna antal diagonalstag anges nedan.<br><br>
        <ul><li>Vid fler än 5 fack: Antal diagonalstag = ((antal fack / 5) * total höjd) / 2 </li>
        <li>Vid färre än 5 fack: Antaldiagonalstag = total höjd / 2 </li></ul><br>
        Detta är det minsta antalet diagonalstag som bör finnas på plats.<br>
        Undantag finns i det fall räcken är konstruerade med fackverk.`,
        title: "Korrekt antal diagonalstag",
        ...DEFAULT_FALSE,
      },
      {
        idx: 1,
        id: "korrektplacering",
        label: "Diagonalstag är korrekt placerade",
        info: `Diagonalstag skall finnas varje bomlag vart femte kommande fack. Undantag finns i det fall räcken är konstruerade med fackverk.`,
        title: "Korrekt placering av diagonalstag",
        images: [diagonalstag],
        ...DEFAULT_FALSE,
      },
    ],
  },
  options4: {
    name: "4. Arbetsplan",
    value: [
      {
        idx: 0,
        id: "fulltintäckt",
        label: "Samtliga arbetsplan är fullt intäckta, kompletta och fastsatta",
        info: `De nivåer och ytor som man behöver använda för arbete eller som tillträdesleder
        ska vara fullt intäckta med arbetsplanskomponenter.
        Arbetsplanet ska vara monterat på ett sådant sätt att dess delar inte oavsiktligen
        kommer ur sitt läge (AFS2013:4 §35). <br><br>
        Generellt sätt anses arbetsplanet vara komplett om det finns skyddsräcke (#1 i bild), fotlister (#2 i bild), samt plank eller trall (#3 i bild).`,
        images: [fullDeck],
        ...DEFAULT_FALSE,
      },
      {
        idx: 1,
        id: "snubbelrisk",
        label: "Arbetsplan fritt från utstickande föremål",
        ...DEFAULT_FALSE,
      },
      {
        idx: 2,
        id: "ställningsplank",
        label: "Ställningsplanken är förbundna (okade) med varandra",
        info: `När man har arbetsplan av ställningsplank på en fasadställning ska samtliga enskilda ställningsplank förbindas.<br>
        Allmänna råd: För ställningsplank finns det särskilda tvärförbind-ningar för sådana ändamål, så kallade ok.<br><br>
        OBS: För andra typer av ställningar där plank används, exempelvis utbredda ställningar, behövs ingen förbindning.<br><br>
        Nedan illustration visar ett plattformslås vilket är ett av många sätt att förbinda ställningsplank (AFS2013:4 §38).`,
        title: "Ställningsplank",
        images: [platformLock],
        ...DEFAULT_FALSE,
      },
      {
        idx: 3,
        id: "avståndtillvägg",
        label: "Ställningens avstånd från vägg är minimerat",
        info: `För att undvika att personer faller till en lägre nivå, och för att undvika risker för olämplig arbetsbelastning, ska avståndet mellan ett arbetsplan och en vägg eller någon annan angränsande konstruktion vara så litet som är praktiskt möjligt. Avståndet får normalt inte överstiga 0,30 m (AFS2013:4 §31).<br><br>
        Allmänna råd: om det inte är möjligt att uppföra ställningen tillräckligt nära fasaden kan man montera konsolplan eller sätta upp skyddsräcke även på ställningens insida (AFS2013:4 §31).
        `,
        title: "Avstånd till vägg",
        ...DEFAULT_FALSE,
      },
      {
        idx: 4,
        id: "oberoendetillträde",
        label: "Korrekt antal oberoende tillträdelseleder",
        info: `En ställning ska ha minst 2 godkända oberoende tillträdesleder.<br>
        Utöver de 2 obligatoriska tillträdelselederna ska ytterligare tillrädelseleder finnas var 25:e meter i längstled (AFS2013:4 §32).<br><br>
        <b>Godkända tillträdelseleder:</b> <br>
        <ul><li>Trapplöp</li><li>Trapptorn</li><li>Fast stege</li><li>Lös stege (ej godkänd för fasad-, rull- och hantverkarställningar)</li></ul><br>
        <b>OBS</b>: 
        <ul><li>Eventuella luckor för tillträde genom arbetsplan ska alltid hållas stängda
        utom när de används för passage (AFS2013:4 §63).</li><li>Fasta stegar över 2 meter måste ha ryggskydd.</li></ul>
        <br><b>Trapptorn:</b>`,
        images: [stairTower],
        ...DEFAULT_FALSE,
      },
    ],
  },
  options5: {
    name: "5. Skydd mot olyckor",
    value: [
      {
        idx: 0,
        id: "tillräckligräckeshöjd",
        label: "Konstruktionen har tillräcklig räckeshöjd",
        title: "Tillräcklig räckeshöjd",
        info: `Räckeshöjden (avståndet D i bilden) ska minst vara 950mm (AFS2013:4, § 28). Normalt 1m (AFS2013:4, §30). I det fall takvinkeln är brant kan en högre räckeshöjd krävas.`,
        images: [railing],
        ...DEFAULT_FALSE,
      },
      {
        idx: 1,
        id: "överledare",
        label: "Arbetsplan har överledare, mellanledare och fotlist",
        title: "Skyddsräcke - överledare, mellanledare och fotlist",
        info: `En ställning ska vara försedd med skyddsräcke där det finns en risk
        att falla två meter eller mer. Där det finns särskild risk ska det finnas skyddsräcke
        även vid lägre fallhöjd.<br><br>
        Skyddsräcket ska vara monterat i anslutning till arbetsplanet så att inget
        farligt mellanrum uppstår mellan arbetsplanet och skyddsräcket. <br>
        Skyddsräcket ska ha tillräcklig styrka och vara säkert fastsatt. Det ska
        vara tillräckligt högt och ska bestå av överledare, mellanledare och fotlist,
        eller ge motsvarande skydd på annat sätt.<br><br>
        Mellanrummet mellan överledare och mellandledare (A i bild) får högst vara 470 mm. <br>
        Fotlisten ska nå upp till 150 mm ovanför arbetsplanets nivå (avstånd C i bild). <br><br>
        På de delar av ställningen som varken används för arbete eller som tillträdesled
        kan fotlisten utelämnas. Fotlist behövs normalt inte heller i trapplöp.<br>
        Om ett väderskyddstak behöver beträdas ska det vara försett med tekniska
        anordningar som skyddar mot att falla till lägre nivå (AFS2013:4, § 28).<br><br>
        OBS: vid takarbeten krävs kortare avstånd mellan överledare, mellanledare och fotlist samt en högre total räckeshöjd beroende på taklutningen.`,
        images: [railing],
        ...DEFAULT_FALSE,
      },
      {
        idx: 2,
        id: "förtätning",
        label: "Förtätning med korrekt skyddsklass",
        info: `Förtätning är endast relevant vid arbete på tak med taklutning och används för att minska öppningar i ett räcke.<br>
        Den maximala storleken på öppningar i ett räcke varierar mellan de olika klasserna, och uppgår till 250 mm för klass B och 100 mm för klass C.
        För klass A är värdet detsamma som för räcken på ställningar, det vill säga 470 mm.`,
        images: [table],
        ...DEFAULT_FALSE,
      },
      {
        idx: 4,
        id: "fallandeföremål",
        label: "Finns risk för fallande föremål?",
        ...DEFAULT_FALSE,
      },
      {
        idx: 5,
        id: "skyddstak",
        label: "Skyddstak används",
        title: "Skyddstak",
        info: `Skyddstak ska finnas vid risk för fallande föremål. Vid trapptorn eller tillträdesleder i direkt anslutning till ställning kan skyddstak vara nödvändigt (AFS2013:4, §29).`,
        ...DEFAULT_FALSE,
      },
      {
        idx: 6,
        id: "trafik",
        label: "Förekommer trafik runt arbetet?",
        ...DEFAULT_FALSE,
      },
      {
        idx: 7,
        id: "påkörningsskydd",
        label: "Påkörningsskydd används",
        title: "Påkörningsskydd",
        info: `I det fall det kan förekomma trafik kan ställningen behövas skyddas mot olyckor som kan ske.<br><br>
        Det kan ibland behövas särskilda tillstånd för att ha en ställning på platsen. Bestämmelserna om tillstånd kan variera beroende på kommun. <br>
        Det kan också behövas särskilda skydd i form av avstängningar för att sätta upp en ställning (AFS2013:4 §49).`,
        ...DEFAULT_FALSE,
      },
    ],
  },
  options6: {
    name: "6. Bärförmåga",
    value: [
      {
        idx: 0,
        id: "fleraställningsnivåer",
        label: "Belastas flera ställningsnivåer samtidigt?",
        title: "Belastas flera ställningsnivåer",
        info: `Som oftast brukar arbete på fasadställningar ske på flera nivåer samtidigt fast inom olika område på ställningen.
        Det viktiga är att inte arbeta på mer än en nivå i samma ställningsfack (se bild för illustrering)`,
        images: [loading],
        ...DEFAULT_FALSE,
      },
      {
        idx: 1,
        id: "dimfleraställningsnivåer",
        label: "Dimensionerat för belastning av flera ställningsnivåer",
        title: "Dimensionerat för flera ställningsnivåer",
        info: `Alla typkontrollintyg (för prefabricerade ställningar) och typfall (för rörställningar) är baserade på max 1 belastat bomlag. <br>
        Om något annat ska gälla måste ställningen dimensioneras särskilt för detta och därmed ha en dimensioneringshandling (AFS2013:4 §66).`,
        images: [loading],
        ...DEFAULT_FALSE,
      },
      {
        idx: 2,
        id: "tungutrustning",
        label: "Finns material/tung utrustning på ställningen?",
        title: "Material / tung utrustning",
        info: `Tung utrustning, tunga maskiner eller dylikt får endast finnas på ställningen om den är dimensionerad för det.
        Motsvarande gäller för maskiner eller annat som ger upphov till dynamiska tilläggslaster (AFS2013:4 §67).`,
        ...DEFAULT_FALSE,
      },
      {
        idx: 3,
        id: "dimtungutrustning",
        label: "Korrekt dimensionering för tung utrustning",
        title: "Dimensionerat för utrustning",
        info: `Det är viktigt att kontrollera att lasterna från tung utrustning eller maskin inte överskrider ställningens tillåtna lastklass.
        Om maskinen dessutom vibrerar så att den innebär en dynamisk belastning, måste ställningen alltid dimensioneras för det. Inget typkontrollintyg innehåller sådana laster.`,
        ...DEFAULT_FALSE,
      },
      {
        idx: 4,
        id: "lastningmedlyftmaskin",
        label: "Pågår lastning av material med lyftmaskin?",
        ...DEFAULT_FALSE,
      },
      {
        idx: 5,
        id: "godkändlastningmedlyftmaskin",
        label:
          "Endast personal som lyfter av/på material vistas på ställningen",
        title: "Pågående lastning av material med lyftmaskin",
        info: `Endast personal som lyfter av/på material får infinna sig på den del av ställningen där pågående lastning av material sker (AFS2013:4 §70).`,
        ...DEFAULT_FALSE,
      },
    ],
  },
  options7: {
    name: "7. Dokumentation",
    value: [
      {
        idx: 0,
        id: "dimensioneringshandlingar",
        label: "Dimensioneringshandlingar / Typfallshandlingar",
        title: "Dimensioneringshandlingar / Typfallshandlingar",
        info: `Typfallshandlingar duger som dimensioneringshandling om ställnignen i handlingen stämmer väl överens med ställningen som byggts. <br>
        Vidare gäller typfall när lastklassen med tillhörande breddklass finns med i intyget och endast arbetsplan på en nivå ska belastas samtidigt, och om ställningen inte ska kläs in. Typfall finns för: <ul>
        <li> 3- och 3+1-planksställning i lastklass 3 </li>
        <li> 5- och 5+1-planksställning i lastklass 4 </li>
        <ul>
        Om ställningen frångår typkontrollintyg genom att ha någon eller några av följande:
          <br><br><ul><li>Inklädnad</li> <li>Lastbrygga</li> <li>Regnskydd</li> <li>Väderskydd</li> <li>Blandat ställningsmaterial</li> <li>Vibrerande tunga maskiner på ställning</li> <li>Flera belastade bomlag</li></ul><br>
          Ska konstruktionens hållfasthet kunna styrkas med dimensioneringshandlingar från Scaffcalc eller liknande aktör (AFS2013:4, §40)`,
        ...DEFAULT_FALSE,
      },
      {
        idx: 1,
        id: "uppförandeplan",
        label: "Plan för uppförande, använding och nedmontering",
        title: "Plan för uppförande, använding och nedmontering",
        info: `Innan arbetet med att uppföra en ställning påbörjas, ska en plan upprättas för hur uppförande, användning och nedmontering ska ske på ett säkert sätt (AFS2013:4 §26).<br>
        Om ett väderskydd ingår i konstruktionen ska även det omfattas av planen. Planen ska upprättas av en person som har god kunskap om och erfarenhet av arbete med ställningar (AFS2013:4 §26).`,
        ...DEFAULT_FALSE,
      },
      {
        idx: 2,
        id: "monteringsinstruktioner",
        label: "Monteringsinstruktioner",
        ...DEFAULT_FALSE,
      },
      {
        idx: 3,
        id: "ställningskontroll",
        label: "Kontroll av ställning - Grön skylt",
        title: "Kontroll av ställning - Grön skylt",
        info: `När byggställningsleverantörer byggt klart ställningen så ska en en kontroll av ställningen ske tillsammans med beställaren för att säkerställa att allt ser bra ut (en så kallad överlämning).<br>
        Vid en sådan kontroll ska även en grön skylt upprättas som monteras synligt på ställningen. Se nedan illustration på hur en grön skylt kan se ut.`,
        images: [greenSign],
        ...DEFAULT_FALSE,
      },
      {
        idx: 4,
        id: "riskbedömning",
        label: "Riskbedömning",
        title: "Riskbedömning",
        info: `Innan man påbörjar arbete på en ställning eller under ett väderskydd,
        ska arbetsgivaren ta reda på förhållandena på arbetsplatsen och bedöma de
        särskilda riskerna där. Arbetsgivaren ska också förvissa sig om att den valda ställningen är lämplig för arbetet (AFS:2013:4 §59).`,
        ...DEFAULT_FALSE,
      },
    ],
  },
  options8: {
    name: "8. Under uppförande ",
    value: [
      {
        idx: 0,
        id: "pågåendeuppförande",
        label: "Pågår uppförande av ställning?",
        ...DEFAULT_FALSE,
      },
      {
        idx: 1,
        id: "ställningstillträdespärrat",
        label: "Ställningstillträde spärrat för obehöriga",
        title: "Ställningstillträde spärrat för obehöriga",
        info: `När ställningar eller väderskydd håller på att uppföras, ändras eller
        monteras ned och inte är färdiga att användas, ska de avgränsas så tydligt
        att tillträde till dem förhindras (AFS2013:4 §48).`,
        ...DEFAULT_FALSE,
      },
      {
        idx: 2,
        id: "arbetsområdespärrat",
        label: "Arbetsområdet spärrat för obehöriga",
        title: "Arbetsområdet spärrat för obehöriga",
        info: `Om det dessutom är förenat med risker att beträda området runt en ställning eller ett väderskydd ska även detta område avgränsas.
        Riskområdet ska märkas ut genom lämplig skyltning (AFS2013:4 §48).`,
        ...DEFAULT_FALSE,
      },
      {
        idx: 3,
        id: "särskildarisker",
        label:
          "Skydd mot särskilda risker, exempelvis trafik, vatten, eller elledningar",
        ...DEFAULT_FALSE,
      },
      {
        idx: 4,
        id: "skyddsutrustning",
        label: "Skyddsutrustning används",
        title: "Skyddsutrustning",
        info: `Olika typer av arbete kräver olika typer av skyddsutrustning.
        Typer av utrustning som kan finnas: <ul><li>Hjälm</li><li>Skyddsglasögon</li><li>Skyddsskor</li><li>Handskar</li></ul>`,
        ...DEFAULT_FALSE,
      },
      {
        idx: 5,
        id: "skyddmotfall",
        label: "Skydd mot fall",
        info: `När en ställning eller ett väderskydd uppförs eller monteras ned, och
        fallhöjden är två meter eller mer, ska arbetstagaren vara skyddad mot risken att falla till lägre nivå (AFS2013:4 §52).<br><br>
        Typer av skydd mot fall som kan finnas:
       <ul><li>Skyddsräcke</li><li>Temporärt räckessystem</li><li>Personlig säkerhetsutrustning</li></ul>`,
        ...DEFAULT_FALSE,
      },
    ],
  },
  options9: {
    name: "9. Utbildning & lärling",
    value: [
      {
        idx: 0,
        id: "utbildningsintyg",
        label: "Montören har giltigt utbildningsintyg på plats",
        ...DEFAULT_FALSE,
      },
      {
        idx: 1,
        id: "rättutbildning",
        label: "Montören har korrekt utbildning för aktuell montering",
        ...DEFAULT_FALSE,
      },
      {
        idx: 2,
        id: "lärling",
        label: "Deltar lärling i arbete?",
        ...DEFAULT_FALSE,
      },
      {
        idx: 5,
        id: "lärlinggodkänd",
        label: "Lärling godkänd",
        title: "Lärling godkänd",
        info: `Lärlingar (som varvar teoretisk utbildning med praktik) får delta i arbete med att uppföra, väsentligen ändra och montera ned ställningar under följande förutsättningar (AFS2013:4 bilaga 3):
        
- varje lärling är under överinseende av ställningsbyggare med yrkesbevis eller kompetensbevis,
- varje ställningsbyggare enligt ovan endast har hand om en lärling,
- varje lärling har lärlingsbok eller annan dokumentation,
- lärlingarna får fortlöpande utbildning, och att utbildningsdelen är planerad,
- lärlingar, som kommer direkt från gymnasieskolan, har nivån allmän utbildning från början, och
- övriga lärlingar får utbildningsnivån allmän utbildning senast inom sex månader från att de påbörjat lärlingsutbildningen.`,
        ...DEFAULT_FALSE,
      },
    ],
  },
  optionsBeslut: {
    value: [
      {
        id: "ställninggodkänd",
        idx: 0,
        label: "Ställning godkänd",
        ispositivechecked: false,
        isnegativechecked: true,
      },
    ],
  },
};

export default OPTIONS;
