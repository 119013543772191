import React, { FC, Fragment, useState } from "react";
import InputField from "../Inputfield";
import Module from "../Module";
import "./NewProject.css";
import Dialog from "../Dialog";
import Markdown from "markdown-to-jsx";
import Button from "../Button";

type NewProjectProps = {
  handleOnChangeInputfield?: any;
  form?: any;
  errors?: any;
};
const NewProject: FC<NewProjectProps> = ({
  handleOnChangeInputfield,
  form,
  errors,
}) => {
  return (
    <div className="PaddingContainer">
      <Module header="Projektinformation">
        <InputContainer
          handleOnChangeInputfield={handleOnChangeInputfield}
          form={form}
          errors={errors}
        />
      </Module>
    </div>
  );
};

type InputContainerProps = {
  handleOnChangeInputfield?: any;
  form?: any;
  errors?: any;
};
const InputContainer: FC<InputContainerProps> = ({
  handleOnChangeInputfield,
  form,
  errors,
}) => {
  const [isDialogInfoOpen, setIsDialogInfoOpen] = useState(false);
  const [row, setRow] = useState<any>({ title: "", info: "" });

  const handleOnClickLocalInfo = (row: any) => {
    setRow(row);
    setIsDialogInfoOpen(true);
  };

  return (
    <Fragment>
      <Dialog
        open={isDialogInfoOpen}
        title={row.title}
        text={<Markdown options={{ wrapper: "div" }}>{row.info}</Markdown>}
        content={
          <Button title="Ok" onClick={() => setIsDialogInfoOpen(false)} />
        }
      />
      <InputField
        id="projektadress"
        placeholder="Projektadress"
        required={true}
        onChange={handleOnChangeInputfield}
        value={form.projektadress.value}
        error={errors.projektadress}
        helperText={"Vänligen fyll i projektadress"}
      />
      <InputField
        id="ställningsentreprenör"
        placeholder="Ställningsentreprenör"
        required={true}
        onChange={handleOnChangeInputfield}
        value={form.ställningsentreprenör.value}
        error={errors.ställningsentreprenör}
        helperText={"Vänligen fyll i ställningsentreprenör"}
        infoButton={true}
        onClickInfo={() =>
          handleOnClickLocalInfo({
            title: "Ställningsentreprenör",
            info: `"Arbetsgivaren ska försäkra sig om att dokumentationen om ställningen
            eller väderskyddet enligt 57 § finns tillgänglig. En arbetsgivare som mottagit
            handlingar enligt 57 § ska se till att handlingarna enligt 57 § 1 och 3–7
            finns tillgängliga på arbetsplatsen."<br><ul><li>AFS2013:4, §60</li></ul><br>
            "Arbetsgivaren ska kontrollera ställningar eller väderskydd innan de
            tas i bruk och även fortlöpande under den tid som arbetstagarna använder dem."<br><ul><li>AFS2013:4, §61</li></ul>`,
          })
        }
      />
      <InputField
        id="beställare"
        placeholder="Beställare"
        required={true}
        onChange={handleOnChangeInputfield}
        value={form.beställare.value}
        error={errors.beställare}
        helperText={"Vänligen fyll i beställare"}
      />
      <InputField
        id="ansvarig"
        placeholder="E-postadress till ansvarig/BAS-U"
        required={false}
        onChange={handleOnChangeInputfield}
        value={form.ansvarig.value}
        infoButton={true}
        onClickInfo={() =>
          handleOnClickLocalInfo({
            title: "E-postadress till ansvarig/BAS-U",
            info: `"Det är viktigt att den som samordnar åtgärder till
            skydd mot ohälsa och olycksfall får tillgång till handlingarna. Om det
            är fråga om byggnads- och anläggningsarbete bör byggarbetsmiljösamordnaren
            för utförandeskedet hålla dem tillgängliga." <br><ul><li>AFS2013:4, Allmänna Råd §57</li></ul>`,
          })
        }
      />
      <InputField
        id="skyddsombud"
        placeholder="E-postadress till skyddsombud"
        required={false}
        onChange={handleOnChangeInputfield}
        value={form.skyddsombud.value}
      />
    </Fragment>
  );
};

export default NewProject;
