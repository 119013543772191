import React, { FC } from "react";
import { Route, Redirect } from "react-router-dom";

type ProtectedRouteProps = {
  component?: any;
  user: any;
  rest?: any;
  exact?: any;
  path?: string;
};
const ProtectedRoute: FC<ProtectedRouteProps> = ({
  component,
  user,
  exact,
  path,
  ...rest
}) => {
  return (
    <Route
      exact={exact}
      path={path}
      {...rest}
      render={(props) => {
        if (user) {
          return component;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
