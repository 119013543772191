import React, { FC, useState, useEffect } from "react";
import EmailIcon from "@material-ui/icons/Email";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import CallIcon from "@material-ui/icons/Call";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import LanguageIcon from "@material-ui/icons/Language";

import HomeIcon from "@material-ui/icons/Home";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { useHistory } from "react-router-dom";

import FullscreenContainer from "../../components/FullscreenContainer";
import NavBarContainer from "../../components/NavBarContainer";
import Inputfield from "../../components/Inputfield";
import Select from "../../components/Select";
import Divider from "../../components/Divider";
import Module from "../../components/Module";
import Button from "../../components/Button";
import Drawer from "../../components/Drawer";
import Typography from "../../components/Typography";
import { logoutUser } from "../../services/firebase";

import "./ProfileScreen.css";

type UserData = {
  offlinePdf?: boolean;
  firstName?: string;
  lastName?: string;
  company?: string;
  email?: string;
  phone?: string;
  role?: string;
};

type ProfileScreenProps = {
  userData?: any;
};

const ProfileScreen: FC<ProfileScreenProps> = ({ userData }) => {
  let history = useHistory();

  const getDrawerOptions = () => {
    const { offlinePdf } = userData;

    if (offlinePdf) {
      return [
        {
          value: (
            <Typography
              type="body1"
              value="Hem"
              style={{ color: "#4d4b51", userSelect: "none" }}
            />
          ),
          icon: <HomeIcon style={{ fill: "#4d4b51" }} />,
          onClick: () => {
            history.push("/form");
          },
        },
        {
          value: (
            <Typography
              type="body1"
              value="Nytt projekt"
              style={{ color: "#4d4b51", userSelect: "none" }}
            />
          ),
          icon: <NoteAddIcon style={{ fill: "#4d4b51" }} />,
          onClick: () => {
            history.push("/form");
          },
        },
      ];
    } else {
      return [
        {
          value: (
            <Typography
              type="body1"
              value="Hem"
              style={{ color: "#4d4b51", userSelect: "none" }}
            />
          ),
          icon: <HomeIcon style={{ fill: "#4d4b51" }} />,
          onClick: () => {
            history.push("/projects");
          },
        },
        {
          value: (
            <Typography
              type="body1"
              value="Nytt projekt"
              style={{ color: "#4d4b51", userSelect: "none" }}
            />
          ),
          icon: <NoteAddIcon style={{ fill: "#4d4b51" }} />,
          onClick: () => {
            history.push("/form");
          },
        },
        {
          value: (
            <Typography
              type="body1"
              value="Mina projekt"
              style={{ color: "#4d4b51", userSelect: "none" }}
            />
          ),
          icon: <AssignmentIcon style={{ fill: "#4d4b51" }} />,
          onClick: () => {
            history.push("/projects");
          },
        },
      ];
    }
  };

  const [open, setOpen] = useState(false);

  return (
    <FullscreenContainer>
      <div className="PaddingContainer">
        <NavBarContainer
          header="Min profil"
          onClickMenu={() => setOpen(true)}
          endContent={
            <Button
              title="Logga ut"
              type="text"
              onClick={() => {
                logoutUser();
              }}
              style={{
                userSelect: "none",
                border: "none",
                textTransform: "none",
                textDecoration: "none",
                display: "inline-block",
              }}
            />
          }
        />
        <Drawer
          user={userData.firstName}
          company={userData.company}
          options={getDrawerOptions()}
          open={open}
          onCloseDrawer={() => setOpen(false)}
          onClickShowProfile={() => {
            setOpen(false);
          }}
        />
        <div data-hj-suppress className="ProfileScreen-Content">
          <Module header="Konto">
            <div style={{ display: "flex" }}>
              <Inputfield
                placeholder="Förnamn"
                value={userData.firstName}
                style={{ marginRight: 5 }}
                disabled={true}
              />
              <Inputfield
                placeholder="Efternamn"
                value={userData.lastName}
                style={{ marginLeft: 5 }}
                disabled={true}
              />
            </div>
            <Inputfield
              placeholder="E-mail"
              value={userData.email}
              style={{ marginTop: 16 }}
              icon={<EmailIcon style={{ fill: "white" }} />}
              disabled={true}
            />
            <Inputfield
              placeholder="Telefonnummer"
              value={userData.phone}
              style={{ marginTop: 16 }}
              icon={<CallIcon style={{ fill: "white" }} />}
              disabled={true}
            />
            <Inputfield
              placeholder="Företag"
              value={userData.company}
              style={{ marginTop: 16 }}
              icon={<BusinessCenterIcon style={{ fill: "white" }} />}
              disabled={true}
            />
            <Inputfield
              placeholder="Arbetsroll"
              value={userData.role}
              style={{ marginTop: 16 }}
              icon={<AssignmentIndIcon style={{ fill: "white" }} />}
              disabled={true}
            />
          </Module>
        </div>
      </div>
      <Divider />
      <div className="PaddingContainer">
        <Module header="Språk">
          <Select
            placeholder="Språk"
            options={[{ value: 0, label: "Svenska" }]}
            value={0}
            icon={<LanguageIcon style={{ fill: "white" }} />}
          />
        </Module>
      </div>
    </FullscreenContainer>
  );
};

export default ProfileScreen;
