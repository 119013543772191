import React, { FC, Fragment } from "react";
import "./Dialog.css";

import DialogMaterial from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "../Button";
import { capitalizeFirstLetter } from "../Utils";

type DialogProps = {
  open: boolean;
  handleOnClickCancel?: any;
  handleOnClickConfirm?: any;
  title?: string;
  text?: any;
  type?: any;
  content?: any;
  textfieldText?: string;
  images?: any[];
};

const Dialog: FC<DialogProps> = ({
  open,
  content,
  handleOnClickCancel,
  handleOnClickConfirm,
  title = "Grattis, snart är byggställningen inspekterad!",
  text = "Genom att klicka Bekräfta nedan så är din inspektion färdig och sparas i Mina projekt.",
  images,
}) => {
  return (
    <DialogMaterial open={open} onClose={handleOnClickCancel}>
      <DialogTitle style={{ color: "black", userSelect: "none" }}>
        {capitalizeFirstLetter(title)}
      </DialogTitle>
      <DialogContent
        style={{ backgroundColor: text !== null ? "white" : "inherit" }}
      >
        <DialogContentText
          style={{ userSelect: "none", color: "black" }}
          component="span"
        >
          {text !== null ? text : "Hittade inget innehåll att visa."}
        </DialogContentText>
        <Fragment>
          {images
            ? images.map((image) => {
                return (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "white",
                      marginTop: 20,
                    }}
                    src={image}
                    alt="Logo"
                  />
                );
              })
            : null}
        </Fragment>
      </DialogContent>
      <DialogActions>
        {content ? (
          content
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
              marginTop: 6,
              marginBottom: 8,
            }}
          >
            <Button
              onClick={handleOnClickCancel}
              title={"Avbryt"}
              type="secondary"
              style={{ marginRight: "1%", minWidth: "45%", color: "black" }}
            />
            <Button
              onClick={handleOnClickConfirm}
              title={"Bekräfta"}
              style={{ marginLeft: "1%", minWidth: "45%" }}
            />
          </div>
        )}
      </DialogActions>
    </DialogMaterial>
  );
};

export default Dialog;
