import React, { FC } from "react";
import "./Typography.css";

import TypographyMaterial from "@material-ui/core/Typography";

type TypographyProps = {
  type: any;
  value?: string;
  style?: any;
  component?: any;
};

const Typography: FC<TypographyProps> = ({
  type = "h1",
  value = "",
  style = {},
  component = "p",
  ...other
}) => {
  return (
    <TypographyMaterial
      variant={type}
      component={component}
      style={{ ...style }}
      {...other}
    >
      {value}
    </TypographyMaterial>
  );
};

export default Typography;
