import React, { FC } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import "./Inputfield.css";

type InputfieldProps = {
  id?: string;
  placeholder?: string;
  onChange?: any;
  disabled?: boolean;
  value?: string;
  style?: any;
  icon?: any;
  type?: any;
  error?: boolean;
  helperText?: any;
  required?: any;
  infoButton?: boolean;
  onClickInfo?: any;
};
const Inputfield: FC<InputfieldProps> = ({
  id,
  placeholder,
  onChange = () => {},
  disabled = false,
  value,
  style,
  icon,
  type,
  error,
  helperText,
  required,
  infoButton,
  onClickInfo,
}) => {
  let getNonIconField = () => (
    <Grid
      className="InputContainer"
      container
      alignItems="center"
      justify="center"
      spacing={1}
      style={style}
    >
      <Grid item style={{ flex: 1, display: "flex" }}>
        <TextField
          id={id}
          label={placeholder}
          onChange={onChange}
          disabled={disabled}
          value={value}
          fullWidth
          type={type}
          size="small"
          margin="dense"
          InputProps={{
            style: { color: "white", borderRadius: 32 },
          }}
          InputLabelProps={{
            style: {
              color: "white",
              userSelect: "none",
            },
          }}
          variant="outlined"
          style={{ ...style, borderRadius: 32 }}
          error={error}
          helperText={error ? helperText : ""}
          required={required}
        />
      </Grid>
      {infoButton ? (
        <Grid item style={{ display: "flex" }}>
          <InfoOutlinedIcon
            style={{ fill: "white", cursor: "pointer" }}
            onClick={onClickInfo}
          />
        </Grid>
      ) : null}
    </Grid>
  );

  let getIconField = () => (
    <Grid
      className="InputContainer"
      container
      alignItems="center"
      justify="center"
      spacing={1}
      style={style}
    >
      <Grid item style={{ display: "flex" }}>
        {icon}
      </Grid>
      <Grid item style={{ flex: 1, display: "flex" }}>
        <TextField
          id={id}
          label={placeholder}
          onChange={onChange}
          disabled={disabled}
          value={value}
          fullWidth
          type={type}
          size="small"
          margin="dense"
          InputProps={{
            style: { color: "white", borderRadius: 32 },
          }}
          InputLabelProps={{
            style: {
              color: "white",
              height: 5,
            },
          }}
          variant="filled"
          error={error}
          helperText={error ? helperText : ""}
          required={required}
        />
      </Grid>
      {infoButton ? (
        <Grid item style={{ display: "flex" }}>
          <InfoOutlinedIcon
            style={{ fill: "white", cursor: "pointer" }}
            onClick={onClickInfo}
          />
        </Grid>
      ) : null}
    </Grid>
  );

  return icon ? getIconField() : getNonIconField();
};

export default Inputfield;
