import React, { FC } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import SelectMaterial from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import "./Select.css";
import { FormHelperText } from "@material-ui/core";

type SelectProps = {
  id?: any;
  placeholder?: string;
  onChange?: any;
  value?: any;
  style?: any;
  options?: any;
  icon?: any;
  required?: any;
  disabled?: any;
  infoButton?: boolean;
  onClickInfo?: any;
  error?: boolean;
  helperText?: string;
};
const Select: FC<SelectProps> = ({
  id,
  placeholder = "",
  onChange = () => {},
  value,
  style,
  icon,
  options = [{ value: 0, label: "" }],
  required,
  disabled,
  infoButton,
  onClickInfo,
  error,
  helperText,
}) => {
  let getNonIconField = () => (
    <Grid
      container
      alignItems="center"
      justify="center"
      spacing={1}
      style={style}
    >
      <Grid item style={{ flex: 1, display: "flex" }}>
        <div
          className="SelectContainer"
          style={{ borderRadius: "32px", ...style }}
        >
          <FormControl
            variant="outlined"
            fullWidth
            required={required}
            disabled={disabled}
            margin="dense"
            size="small"
            style={{ borderRadius: "32px" }}
            error={error}
          >
            <InputLabel style={{ color: "white", borderRadius: "32px" }}>
              {placeholder}
            </InputLabel>
            <SelectMaterial
              onChange={onChange}
              value={value}
              id={id ? id : ""}
              style={{ borderRadius: "32px" }}
            >
              {options.map((val: any, idx: number) => {
                return (
                  <MenuItem key={idx} value={val.value}>
                    {val.label}
                  </MenuItem>
                );
              })}
            </SelectMaterial>
            {error ? <FormHelperText>{helperText}</FormHelperText> : null}
          </FormControl>
        </div>
      </Grid>
      {infoButton ? (
        <Grid item style={{ display: "flex" }} onClick={onClickInfo}>
          <InfoOutlinedIcon style={{ fill: "white", cursor: "pointer" }} />
        </Grid>
      ) : null}
    </Grid>
  );

  let getIconField = () => (
    <Grid
      container
      alignItems="center"
      justify="center"
      spacing={1}
      style={style}
    >
      <Grid item style={{ display: "flex" }}>
        {icon}
      </Grid>
      <Grid item style={{ flex: 1, display: "flex" }}>
        <div className="SelectContainer" style={style}>
          <FormControl
            variant="filled"
            fullWidth
            disabled={disabled}
            error={error}
          >
            <InputLabel>{placeholder}</InputLabel>
            <SelectMaterial onChange={onChange} value={value} id={id ? id : ""}>
              {options.map((val: any, idx: number) => {
                return (
                  <MenuItem key={idx} value={val.value}>
                    {val.label}
                  </MenuItem>
                );
              })}
            </SelectMaterial>
            <FormHelperText>{helperText}</FormHelperText>
          </FormControl>
        </div>
      </Grid>
      {infoButton ? (
        <Grid item style={{ display: "flex" }} onClick={onClickInfo}>
          <InfoOutlinedIcon style={{ fill: "white", cursor: "pointer" }} />
        </Grid>
      ) : null}
    </Grid>
  );

  return icon ? getIconField() : getNonIconField();
};

export default Select;
