import React, { FC, Fragment } from "react";
import "./Module.css";

import Typography from "../Typography";

type ModuleProps = {
  header?: string;
};
const Module: FC<ModuleProps> = ({ header, children }) => {
  return (
    <Fragment>
      <div
        className="Module-Header"
        style={{ color: "white", userSelect: "none" }}
      >
        <Typography type="h6" value={header} />
      </div>
      <div className="Module-Children">{children}</div>
    </Fragment>
  );
};

export default Module;
