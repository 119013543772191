import React, { FC, useState, Fragment } from "react";
import Button from "../../components/Button";
import Inputfield from "../../components/Inputfield";

type InputContainerProps = {
  email?: string;
  emailError?: any;
  handleOnClickLogin?: any;
  handleOnChangeEmail?: any;
  handleOnChangePassword?: any;
  handleOnClickForgotPassword?: any;
  isErrorEmail?: any;
  password?: string;
};
const InputContainer: FC<InputContainerProps> = ({
  email,
  emailError,
  handleOnClickLogin,
  handleOnChangeEmail,
  handleOnChangePassword,
  handleOnClickForgotPassword,
  isErrorEmail,
  password,
}) => {
  const [screen, setScreen] = useState<string>("login");
  return (
    <div className="InputContainer-LoginScreen">
      {screen === "login" ? (
        <Fragment>
          <Inputfield
            style={{ marginBottom: 12 }}
            placeholder="E-mailadress"
            type="email"
            onChange={handleOnChangeEmail}
            error={isErrorEmail}
            helperText={isErrorEmail ? emailError : ""}
            value={email}
          />
          <Inputfield
            style={{ marginBottom: 12 }}
            placeholder="Lösenord"
            type="password"
            onChange={handleOnChangePassword}
            value={password}
          />
        </Fragment>
      ) : (
        <Inputfield
          style={{ marginBottom: 12 }}
          placeholder="E-mailadress"
          type="email"
          onChange={handleOnChangeEmail}
          value={email}
        />
      )}
      <div
        className="ButtonContainer-LoginScreen"
        style={{
          marginBottom: 24,
          justifyContent: screen === "forgot" ? "center" : "space-between",
        }}
      >
        {screen === "login" ? (
          <Fragment>
            <Button
              style={{ minWidth: "45%" }}
              title="Logga in"
              type="primary"
              onClick={handleOnClickLogin}
              disabled={
                isErrorEmail || email?.length === 0 || password?.length === 0
              }
            />
            <Button
              style={{ minWidth: "45%" }}
              title="Kontakta oss"
              type="secondary"
              onClick={() => {
                window.open("https://scaffcalc.se/kontakt/");
              }}
            />
          </Fragment>
        ) : (
          <Button
            style={{ minWidth: "60%" }}
            title="Återställ lösenord"
            type="primary"
            onClick={handleOnClickForgotPassword}
            disabled={isErrorEmail || email?.length === 0}
          />
        )}
      </div>
      <div>
        {screen === "login" ? (
          <Button
            title="Glömt lösenord?"
            type="text"
            onClick={() => setScreen("forgot")}
          />
        ) : (
          <Button
            title="Tillbaka"
            type="text"
            onClick={() => setScreen("login")}
          />
        )}
      </div>
    </div>
  );
};

export default InputContainer;
