import React, { FC, useState, useEffect } from "react";
import FullscreenContainer from "../../components/FullscreenContainer";
import NavBarContainer from "../../components/NavBarContainer";
import Table from "../../components/Table";

import Button from "../../components/Button";
import Drawer from "../../components/Drawer";
import Typography from "../../components/Typography";
import Loading from "../../components/Loading";
import Dialog from "../../components/Dialog";
import Snackbar from "../../components/Snackbar";
import INIT from "../FormScreen/init";

import HomeIcon from "@material-ui/icons/Home";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import AssignmentIcon from "@material-ui/icons/Assignment";

import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import {
  getUserProjects,
  getUserFormPDfUrl,
  deleteFile,
  getProjectByID,
  getUser,
} from "../../services/firebase";

import { useHistory } from "react-router-dom";

import "./ProjectScreen.css";
import { faTransgenderAlt } from "@fortawesome/free-solid-svg-icons";

type ProjectScreenProps = { userData?: any; setEditForm?: any };
const ProjectScreen: FC<ProjectScreenProps> = ({ userData, setEditForm }) => {
  let history = useHistory();

  const options = [
    {
      value: (
        <Typography
          type="body1"
          value="Hem"
          style={{ color: "#4d4b51", userSelect: "none" }}
        />
      ),
      icon: <HomeIcon style={{ fill: "#4d4b51" }} />,
      onClick: () => {
        setOpen(false);
      },
    },
    {
      value: (
        <Typography
          type="body1"
          value="Nytt projekt"
          style={{ color: "#4d4b51", userSelect: "none" }}
        />
      ),
      icon: <NoteAddIcon style={{ fill: "#4d4b51" }} />,
      onClick: () => {
        history.push("/form");
      },
    },
    {
      value: (
        <Typography
          type="body1"
          value="Mina projekt"
          style={{ color: "#4d4b51", userSelect: "none" }}
        />
      ),
      icon: <AssignmentIcon style={{ fill: "#4d4b51" }} />,
      onClick: () => {
        setOpen(false);
      },
    },
  ];

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [projects, setProjects] = useState<any>([]);
  const [project, setProject] = useState<any>({});
  const [pageNr, setPageNr] = useState<number>(0);
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [pages, setPages] = useState<any>([]);
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  useEffect(() => {
    let tempPages = [];
    for (let i = 0; i < pageNr; i++) {
      tempPages.push(
        <div
          key={i}
          style={{
            borderStyle: "none none solid none",
            borderWidth: 1,
            borderColor: "black",
          }}
        >
          <Page loading={`Laddar sida: ${i + 1}`} pageNumber={i + 1} />
        </div>
      );
    }
    setPages(tempPages);
  }, [pageNr]);

  useEffect(() => {
    setLoading(true);
    getUserProjects()
      ?.then((projectSnap: any) => {
        const cleanProjects: any = [];
        projectSnap.forEach((projectDoc: any) => {
          const data = projectDoc.data();

          let name = data.form.info.value.filter(
            (x: any) => x.label === "Projektadress"
          )[0].value;

          cleanProjects.push({
            namn: name,
            ägare: data.email,
            skapad: data.timestamp.toDate().toLocaleDateString(),
            timestamp_iso: data.timestamp.toDate().toISOString(),
            docId: projectDoc.id,
            subRow: [],
          });
        });

        setProjects(cleanProjects);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  function sleep(time?: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const onClickContextmenu = (target: any) => {
    let projectName = "";
    switch (target.type) {
      case "tabort":
        projectName = projects.filter(
          (project: any) => project.docId === target.docId
        )[0].namn;
        setProject({
          timestamp: target.timestamp,
          id: target.docId,
          name: projectName,
        });
        setIsDialogOpen(true);
        break;
      case "öppna":
        showFile(target.timestamp, target.docId);
        break;
      case "dela":
        projectName = projects.filter(
          (project: any) => project.docId === target.docId
        )[0].namn;
        emailFile(projectName, target.timestamp, target.docId);
        break;
      case "redigera":
        editForm(target.timestamp, target.docId);
        history.push("/form");
        break;
      default:
        break;
    }
  };

  const emailFile = (projectName: string, timestamp: string, docId: string) => {
    if (
      timestamp !== undefined &&
      docId !== undefined &&
      projectName !== undefined
    ) {
      getUserFormPDfUrl(timestamp, docId)?.then((url: any) => {
        const anchor = `Du kan kolla in inspektionen genom att clicka på länken: ${url} \n\n Med vänlig hälsning,`;
        window.open(
          `mailto:?subject=Inbjudan till att se Inspektion: ${encodeURIComponent(
            projectName
          )}&body=${encodeURIComponent(anchor)}`
        );
      });
    }
  };

  const showFile = (timestamp: string, docId: string) => {
    if (timestamp !== undefined && docId !== undefined) {
      const windowReference: any = window.open();
      getUserFormPDfUrl(timestamp, docId)
        ?.then((url) => {
          windowReference.location = url;
        })
        .catch((err) => {
          setSnackbarSeverity("error");
          setIsSnackbarOpen(true);
          setSnackbarMessage(err.message);
          windowReference.close();
          sleep(3000).then(() => {
            setIsSnackbarOpen(false);
          });
        });
    }
  };

  const editForm = (timestamp: string, docId: string) => {
    getProjectByID(docId).then((doc) => {
      if (doc.exists) {
        let data = doc.data();
        if (data !== undefined) {
          let form = translateDBForm(data.form);
          form.oldIsoTimestamp = timestamp;
          form.formId = docId;
          setEditForm(form);
        }
      } else {
        setSnackbarSeverity("error");
        setIsSnackbarOpen(true);
        setSnackbarMessage("Could not fetch inspection");
        sleep(3000).then(() => {
          setIsSnackbarOpen(false);
        });
      }
    });
  };

  const translateDBForm = (dbForm: any) => {
    const form: any = JSON.parse(JSON.stringify(INIT)); //Clone object, must be JSON safe

    try {
      if (dbForm.info !== undefined) {
        dbForm.info.value.map((field: any) => {
          if (field.label === "BAS-U") {
            form.ansvarig = field;
          } else {
            form[
              field.label.charAt(0).toLowerCase() + field.label.slice(1)
            ] = field;
          }
        });
      }

      if (dbForm.notes !== undefined) {
        form.notes = dbForm.notes.value;
      }

      if (dbForm.decision !== undefined) {
        form.ställninggodkänd.value = dbForm.decision.value[0].value;
      }
      if (dbForm.controlData !== undefined) {
        dbForm.controlData.value.map((listItem: { [index: string]: any }) => {
          switch (listItem.label) {
            case "Underpallningens storlek":
              form.underpallningensstorlek = listItem;
              break;
            case "Typ av arbete":
              form.typavarbete = listItem;
              break;
            default:
              form[
                listItem.label.charAt(0).toLowerCase() + listItem.label.slice(1)
              ] = listItem;
              break;
          }
        });
      }

      if (dbForm.checklist !== undefined) {
        dbForm.checklist.value.map((listItem: any) => {
          switch (listItem.label) {
            case "1. Underlag, placering och utformning":
              listItem.value.map((subItem: any) => {
                switch (subItem.label) {
                  case "Spiror/fötter vilar på underpallningen och är centrerade":
                  case "Spiror/fötter centrerade på underpallningen":
                    form.spirorcentrerade.value = subItem.value;
                    break;
                  case "Kil används vid lutande underlag":
                  case "Kil vid lutande underlag":
                    form.killutandeunderlag.value = subItem.value;
                    break;
                  case "Anteckningar":
                    form.notes1 = subItem;
                    break;
                }
              });
              break;
            case "2. Förankringar":
              listItem.value.map((subItem: any) => {
                switch (subItem.label) {
                  case "Förankringar är godkänt placerade":
                  case "Godkänd placering":
                    form.godkändplacering.value = subItem.value;
                    break;
                  case "V-förankring används vart femte fack":
                  case "V-förankring vart femte fack":
                    form.vförankringar.value = subItem.value;
                    break;
                  case "Provdragningsprotokoll finns på plats":
                  case "Provdragningsprotokoll":
                    form.provdragningsprotokoll.value = subItem.value;
                    break;
                  case "Anteckningar":
                    form.notes2 = subItem;
                }
              });
              break;
            case "3. Diagonalstagning":
              listItem.value.map((subItem: any) => {
                switch (subItem.label) {
                  case "Korrekt antal":
                    form.korrektantal.value = subItem.value;
                    break;
                  case "Diagonalstagen är placerade korrekt":
                  case "Korrekt placering":
                    form.korrektplacering.value = subItem.value;
                    break;
                  case "Anteckningar":
                    form.notes3 = subItem;
                    break;
                }
              });
              break;
            case "4. Avstånd till vägg":
              listItem.value.map((subItem: any) => {
                if (
                  subItem.label === "Max 30 cm" ||
                  subItem.label === "Avstånd till vägg (max 30cm)"
                ) {
                  form.avståndtillvägg.value = subItem.value;
                }
              });
              break;
            case "4. Arbetsplan":
            case "5. Arbetsplan":
              listItem.value.map((subItem: any) => {
                switch (subItem.label) {
                  case "Samtliga arbetsplan är fullt intäckta och kompletta":
                  case "Fullt intäckt":
                    form.fulltintäckt.value = subItem.value;
                    break;
                  case "Komponenter fastsatta":
                    form.komponenterfastsatta.value = subItem.value;
                    break;
                  case "Är arbetsplanet fritt från utstickande föremål?":
                  case "Snubbelrisk åtgärdad":
                    form.snubbelrisk.value = subItem.value;
                    break;
                  case "Ställningsplanken är förbundna (okade) med varandra":
                  case "Ställningsplank tvärförbindande med ok":
                    form.ställningsplank.value = subItem.value;
                    break;
                  case "Ställningens avstånd från vägg är max 30 cm":
                    form.avståndtillvägg.value = subItem.value;
                    break;
                  case "Korrekt antal oberoende tillträdelseleder":
                    form.oberoendetillträde.value = subItem.value;
                    break;
                  case "Anteckningar":
                    form.notes4 = subItem;
                    break;
                }
              });
              break;
            case "6. Tillträde":
              listItem.value.map((subItem: any) => {
                if (subItem.label === "Två oberoende tillträde") {
                  form.oberoendetillträde.value = subItem.value;
                }
              });
              break;
            case "5. Skydd mot olyckor":
            case "7. Skydd mot olyckor":
              listItem.value.map((subItem: any) => {
                switch (subItem.label) {
                  case "Har konstruktionen tillräcklig räckeshöjd?":
                  case "Tillräcklig räckeshöjd":
                    form.tillräckligräckeshöjd.value = subItem.value;
                    break;
                  case "Finns överledare, mellanledare och fotlist på arbetsplan?":
                  case "Överledare, mellanledare och fotlist":
                    form.överledare.value = subItem.value;
                    break;
                  case "Finns förtätning med korrekt skyddsklass?":
                  case "Förtätning (korrekt skyddsklass)":
                    form.förtätning.value = subItem.value;
                    break;
                  case "Finns risk för fallande föremål?":
                    form.fallandeföremål.value = subItem.value;
                    break;
                  case "Skyddstak":
                    form.skyddstak.value = subItem.value;
                    break;
                  case "Förekommer trafik runt arbetet?":
                    form.trafik.value = subItem.value;
                    break;
                  case "Påkörningsskydd":
                    form.påkörningsskydd.value = subItem.value;
                    break;
                  case "Anteckningar":
                    form.notes5 = subItem;
                    break;
                }
              });
              break;
            case "6. Bärförmåga":
            case "8. Bärförmåga":
              listItem.value.map((subItem: any) => {
                switch (subItem.label) {
                  case "Används flera ställningsnivåer samtidigt?":
                    form.fleraställningsnivåer.value = subItem.value;
                    break;
                  case "Har entreprenören dimensionerat för flera ställningsnivåer?":
                    form.dimfleraställningsnivåer.value = subItem.value;
                    break;
                  case "Finns material/tung utrustning på ställningen?":
                    form.tungutrustning.value = subItem.value;
                    break;
                  case "Har entreprenören dimensionerat för tung utrustning?":
                    form.dimtungutrustning.value = subItem.value;
                    break;
                  case "Pågår lastning av material med lyftmaskin?":
                  case "Pågående lastning av material med lyftmaskin":
                    form.lastningmedlyftmaskin.value = subItem.value;
                    break;
                  case "Vistas endast personal som lyfter av/på material på ställningen?":
                    form.godkändlastningmedlyftmaskin.value = subItem.value;
                    break;
                  case "Anteckningar":
                    form.notes6 = subItem;
                    break;
                }
              });
              break;
            case "7. Dokumentation":
            case "9. Dokumentation":
              listItem.value.map((subItem: any) => {
                switch (subItem.label) {
                  case "Dimensioneringshandlingar, AFS":
                    form.dimensioneringshandlingar.value = subItem.value;
                    break;
                  case "Plan för uppförande, använding och nedmontering":
                    form.uppförandeplan.value = subItem.value;
                    break;
                  case "Monteringsinstruktioner":
                    form.monteringsinstruktioner.value = subItem.value;
                    break;
                  case "Kontroll av ställning - överlämning":
                    form.ställningskontroll.value = subItem.value;
                    break;
                  case "Riskbedömning":
                    form.riskbedömning.value = subItem.value;
                    break;
                  case "Anteckningar":
                    form.notes7 = subItem;
                    break;
                }
              });
              break;
            case "8. Under uppförande":
            case "10. Under uppförande":
              listItem.value.map((subItem: any) => {
                switch (subItem.label) {
                  case "Pågår uppförande av ställning?":
                    form.pågåendeuppförande.value = subItem.value;
                    break;
                  case "Ställningstillträde spärrat":
                  case "Är ställningstillträde spärrat för obehöriga?":
                    form.ställningstillträdespärrat.value = subItem.value;
                    break;
                  case "Arbetsområde spärrat":
                  case "Är arbetsområdet spärrat för obehöriga?":
                    form.arbetsområdespärrat.value = subItem.value;
                    break;
                  case "Skydd mot särskilda risker (trafik, vatten, elledningar m.m.)":
                  case "Finns skydd mot särskilda risker? Exempelvis trafik, vatten, eller elledningar":
                    form.särskildarisker.value = subItem.value;
                    break;
                  case "Används skyddsutrustning?":
                    form.skyddsutrustning.value = subItem.value;
                    break;
                  case "Skydd mot fall":
                    form.skyddmotfall.value = subItem.value;
                    break;
                  case "Anteckningar":
                    form.notes8 = subItem;
                    break;
                }
              });
              break;
            case "9. Utbildning & lärling":
            case "11. Utbildning & lärling":
              listItem.value.map((subItem: any) => {
                switch (subItem.label) {
                  case "Har montören giltigt utbildningsintyg på plats?":
                    form.utbildningsintyg.value = subItem.value;
                    break;
                  case "Har montören rätt utbildning för aktuell montering?":
                    form.rättutbildning.value = subItem.value;
                    break;
                  case "Skydd mot särskilda risker (trafik, vatten, elledningar m.m.)":
                    form.särskildarisker.value = subItem.value;
                    break;
                  case "Deltar lärling i arbete?":
                    form.lärling.value = subItem.value;
                    break;
                  case "Har varje lärling en handledare?":
                    form.lärarelärling.value = subItem.value;
                    break;
                  case "Finns lärlingsbok på arbetsplats?":
                    form.lärlingsbok.value = subItem.value;
                    break;
                  case "Är lärlingen godkänd?":
                    form.lärlinggodkänd.value = subItem.value;
                    break;
                  case "Anteckningar":
                    form.notes9 = subItem;
                    break;
                }
              });
              break;
          }
        });
      }

      form.edit = true;
    } catch (error) {
      setSnackbarSeverity("error");
      setIsSnackbarOpen(true);
      setSnackbarMessage("Could not read inspection data");
      sleep(3000).then(() => {
        setIsSnackbarOpen(false);
      });
    }

    return form;
  };

  const deleteDocument = (timestamp: string, docId: string, name: string) => {
    setIsDialogOpen(false);
    if (timestamp !== undefined && docId !== undefined) {
      deleteFile(timestamp, docId)
        ?.then(() => {
          let remainingProjects = projects.filter(
            (project: any) => project.docId !== docId
          );
          setProjects(remainingProjects);
          setSnackbarSeverity("success");
          setIsSnackbarOpen(true);
          setSnackbarMessage(`inspektion: ${name}, har raderats`);
        })
        .catch((err) => {
          setSnackbarSeverity("error");
          setIsSnackbarOpen(true);
          setSnackbarMessage(err.message);
        });
      sleep(3000).then(() => {
        setIsSnackbarOpen(false);
      });

      setProject({});
    }
  };

  return (
    <FullscreenContainer className="ProjectScreen-FullscreenContainer">
      <Loading isOpen={loading} />
      <Snackbar
        text={snackbarMessage}
        severity={snackbarSeverity}
        isOpen={isSnackbarOpen}
      />
      <Dialog
        title={`Inspektion: ${project.name}`}
        text="Är du säker på att du vill radera inspektionen?"
        open={isDialogOpen}
        content={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                marginTop: 24,
                marginBottom: 16,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Button
                style={{
                  width: "45%",
                  backgroundColor: "red",
                  marginRight: 10,
                }}
                onClick={() =>
                  deleteDocument(project.timestamp, project.id, project.name)
                }
                title={"Radera"}
              />
              <Button
                style={{ width: "45%", backgroundColor: "green" }}
                onClick={() => setIsDialogOpen(false)}
                title={"Ångra"}
              />
            </div>
          </div>
        }
      />

      <div className="PaddingContainer">
        <NavBarContainer
          onClickMenu={() => setOpen(true)}
          header="Mina projekt"
        />
        <Drawer
          user={userData.firstName}
          company={userData.company}
          options={options}
          open={open}
          onCloseDrawer={() => setOpen(false)}
          onClickShowProfile={() => {
            history.push("/profile");
          }}
        />
      </div>
      <div className="ProjectScreen-Table">
        <Table
          rows={projects}
          onClickContextmenu={onClickContextmenu}
          onDoubleClickRow={showFile}
        />
      </div>
    </FullscreenContainer>
  );
};

export default ProjectScreen;
