import React, { FC, Fragment, useState } from "react";
import InputField from "../Inputfield";
import Select from "../Select";
import Module from "../Module";
import Divider from "../Divider";
import Button from "../Button";
import Dialog from "../Dialog";
import "./Control.css";
import OPTIONS from "./options";
import Markdown from "markdown-to-jsx";

type ControlProps = {
  handleOnChangeInputfield?: any;
  handleOnChangeSelect?: any;
  handleOnClickInfo?: any;
  form?: any;
  errors?: any;
};
const Control: FC<ControlProps> = ({
  handleOnChangeInputfield,
  handleOnChangeSelect,
  handleOnClickInfo,
  form,
  errors,
}) => {
  const [isDialogInfoOpen, setIsDialogInfoOpen] = useState(false);
  const [row, setRow] = useState<any>({ title: "", info: "", images: null });

  const handleOnClickLocalInfo = (row: any) => {
    setRow(row);
    setIsDialogInfoOpen(true);
  };

  return (
    <Fragment>
      <Dialog
        open={isDialogInfoOpen}
        title={row.title}
        text={<Markdown options={{ wrapper: "div" }}>{row.info}</Markdown>}
        content={
          <Button title="Ok" onClick={() => setIsDialogInfoOpen(false)} />
        }
        images={row.images}
      />
      <div data-hj-suppress className="PaddingContainer">
        <Module header="Ställningsinformation">
          <Dimensions
            handleOnChangeInputfield={handleOnChangeInputfield}
            handleOnChangeSelect={handleOnChangeSelect}
            handleOnClickLocalInfo={handleOnClickLocalInfo}
            handleOnClickInfo={handleOnClickInfo}
            form={form}
            errors={errors}
          />
          <Information
            handleOnChangeSelect={handleOnChangeSelect}
            handleOnClickLocalInfo={handleOnClickLocalInfo}
            form={form}
            errors={errors}
          />
        </Module>
      </div>
      <Divider />
      <div className="PaddingContainer">
        <Module header="Förankringshöjd och inklädnad">
          <Anchors
            handleOnChangeSelect={handleOnChangeSelect}
            handleOnClickLocalInfo={handleOnClickLocalInfo}
            handleOnClickInfo={handleOnClickInfo}
            form={form}
            errors={errors}
          />
        </Module>
      </div>
      <Divider />
      <div className="PaddingContainer">
        <Module header="Terrängtyp och vindhastighet">
          <Terrain
            handleOnChangeSelect={handleOnChangeSelect}
            handleOnClickInfo={handleOnClickInfo}
            form={form}
            errors={errors}
          />
        </Module>
      </div>
      <Divider />
      <div className="PaddingContainer">
        <Module header="Övrig information">
          <Misc
            handleOnChangeSelect={handleOnChangeSelect}
            handleOnClickLocalInfo={handleOnClickLocalInfo}
            form={form}
            errors={errors}
          />
        </Module>
      </div>
    </Fragment>
  );
};

type DimensionsProps = {
  handleOnChangeInputfield?: any;
  handleOnChangeSelect?: any;
  handleOnClickLocalInfo?: any;
  handleOnClickInfo?: any;
  form: any;
  errors?: any;
};
const Dimensions: FC<DimensionsProps> = ({
  handleOnChangeInputfield,
  handleOnChangeSelect,
  handleOnClickLocalInfo,
  handleOnClickInfo,
  form,
  errors,
}) => {
  let out = (
    <Fragment>
      <InputField
        id="ställningshöjd"
        placeholder="Ställningshöjd"
        required={true}
        onChange={(e: any) => handleOnChangeInputfield(e, 0, 50)}
        type={"number"}
        value={form.ställningshöjd.value}
        infoButton={true}
        error={errors.ställningshöjd}
        helperText={"Ange ställningshöjd"}
        onClickInfo={() =>
          handleOnClickLocalInfo({
            title: "Ställningshöjd",
            info: `Ställningens högsta höjd. Används för att beräkna vindlaster som uppkommer.`,
          })
        }
      />
      <InputField
        id="ställningslängd"
        placeholder="Ställningslängd"
        required={true}
        onChange={handleOnChangeInputfield}
        type={"number"}
        value={form.ställningslängd.value}
        infoButton={true}
        error={errors.ställningslängd}
        helperText={"Ange ställningslängd"}
        onClickInfo={() =>
          handleOnClickLocalInfo({
            title: "Ställningslängd",
            info: `Ställningens totala längd längst fasad. Om ställningen går runt ett hörn, ange totalt stegad längd.`,
          })
        }
      />
      <Select
        id={OPTIONS.facklängd.id}
        placeholder="Dimensionerande facklängd"
        options={OPTIONS.facklängd.value}
        required={true}
        onChange={(e?: any) => handleOnChangeSelect(e, OPTIONS.facklängd.id)}
        value={form.facklängd.value}
        infoButton={true}
        error={errors.facklängd}
        helperText={"Ange dimensionerande facklängd"}
        style={{ borderRadius: "32px" }}
        onClickInfo={() => handleOnClickInfo(OPTIONS.facklängd.id)}
      />
    </Fragment>
  );

  return out;
};

type AnchorsProps = {
  handleOnChangeSelect?: any;
  handleOnClickLocalInfo?: any;
  handleOnClickInfo?: any;
  form: any;
  errors?: any;
};
const Anchors: FC<AnchorsProps> = ({
  handleOnChangeSelect,
  handleOnClickLocalInfo,
  handleOnClickInfo,
  form,
  errors,
}) => {
  let out = (
    <Fragment>
      <Select
        id={OPTIONS.förankringshöjd.id}
        placeholder="Förankringstäthet"
        options={OPTIONS.förankringshöjd.value}
        required={true}
        onChange={(e?: any) =>
          handleOnChangeSelect(e, OPTIONS.förankringshöjd.id)
        }
        value={form.förankringshöjd.value}
        infoButton={true}
        error={errors.förankringshöjd}
        helperText={"Ange förankringshöjd"}
        onClickInfo={() => handleOnClickInfo(OPTIONS.förankringshöjd.id)}
      />
      <Select
        id={OPTIONS.inklädnad.id}
        placeholder="Inklädnad"
        options={OPTIONS.inklädnad.value}
        required={true}
        onChange={(e?: any) => handleOnChangeSelect(e, OPTIONS.inklädnad.id)}
        value={form.inklädnad.value}
        infoButton={true}
        error={errors.inklädnad}
        helperText={"Ange inklädnad"}
        onClickInfo={() =>
          handleOnClickLocalInfo({
            title: "Inklädnad",
            info: `Hållfasthet för nätade & inplastade ställningar ska kunna styrkas med kompletterande vindlastberäkningar då dessa frångår typkontrollintyg.
            Inklädnad kan verka som ett segel och dra ut ställningen från väggen. Kraften från seglet beror på vart i landet ställningen står och i vilken terrängtyp,
            varför särskilda beräkningar bör finnas vid inklädd ställning.`,
          })
        }
      />
    </Fragment>
  );

  return out;
};

type TerrainProps = {
  handleOnChangeSelect?: any;
  form: any;
  handleOnClickInfo?: any;
  errors?: any;
};
const Terrain: FC<TerrainProps> = ({
  handleOnChangeSelect,
  handleOnClickInfo,
  form,
  errors,
}) => {
  let out = (
    <Fragment>
      <Select
        onClickInfo={() => {
          handleOnClickInfo(OPTIONS.vindhastighet.id);
        }}
        infoButton={true}
        id={OPTIONS.vindhastighet.id}
        placeholder="Vindhastighet"
        options={OPTIONS.vindhastighet.value}
        required={true}
        error={errors.vindhastighet}
        helperText={"Ange vindhastighet"}
        onChange={(e?: any) =>
          handleOnChangeSelect(e, OPTIONS.vindhastighet.id)
        }
        value={form.vindhastighet.value}
      />
      <Select
        onClickInfo={() => {
          handleOnClickInfo(OPTIONS.terrängtyp.id);
        }}
        infoButton={true}
        id={OPTIONS.terrängtyp.id}
        placeholder="Terrängtyp"
        options={OPTIONS.terrängtyp.value}
        error={errors.terrängtyp}
        helperText={"Ange terrängtyp"}
        required={true}
        onChange={(e?: any) => handleOnChangeSelect(e, OPTIONS.terrängtyp.id)}
        value={form.terrängtyp.value}
      />
    </Fragment>
  );

  return out;
};

type InformationProps = {
  handleOnChangeSelect?: any;
  handleOnClickLocalInfo?: any;
  form: any;
  errors?: any;
};
const Information: FC<InformationProps> = ({
  handleOnChangeSelect,
  handleOnClickLocalInfo,
  form,
  errors,
}) => {
  let out = (
    <Fragment>
      <Select
        id={OPTIONS.ställningstyp.id}
        placeholder="Ställningstyp"
        options={OPTIONS.ställningstyp.value}
        value={form.ställningstyp.value}
        required={true}
        onChange={(e?: any) =>
          handleOnChangeSelect(e, OPTIONS.ställningstyp.id)
        }
        infoButton={true}
        error={errors.ställningstyp}
        helperText={"Ange ställningstyp"}
        onClickInfo={() =>
          handleOnClickLocalInfo({
            title: "Ställningstyp",
            info: `Överrensstämmelse med SP Arbetsrapport 2006:58 vid användning av rör och koppling kan ligga till grund för dimensionering<br>
            <ul><li>AFS2013:4 §40</li></ul><br> "Typkontroll för prefabricerade ställningar, komponenter till prefabricerade
            ställningar och kopplingar som gjorts med stöd av Arbetarskyddsstyrelsens
            föreskrifter (AFS1990:12) om ställningar gäller också som typkontroll
            enligt 10 §."<br><ul><li>AFS2013:4 §75</li></ul>
            `,
          })
        }
      />
      <Select
        id={OPTIONS.ställningsleverantör.id}
        placeholder="Ställningsleverantör"
        options={OPTIONS.ställningsleverantör.value}
        value={form.ställningsleverantör.value}
        required={true}
        onChange={(e?: any) =>
          handleOnChangeSelect(e, OPTIONS.ställningsleverantör.id)
        }
        infoButton={true}
        error={errors.ställningsleverantör}
        helperText={"Ange ställningsleverantör"}
        onClickInfo={() =>
          handleOnClickLocalInfo({
            title: "Ställningsleverantör",
            info: `Valet av ställningsleverantör kan påverka ställningens hållfasthet.
            Vid blandat material finns generellt inga giltiga typkontrollintyg, varför särskilda dimensioneringshandlingar bör finnas.
            Om komponenter från andra prefabricerade ställningssystem används
            i en enskild ställning, ska en särskild utredning göras för att visa att säkerheten
            är betryggande. Utredningen ska dokumenteras i de särskilda dimensioneringshandlingarna.
            AFS2013:4, §41`,
          })
        }
      />
      <Select
        id={OPTIONS.lastklass.id}
        placeholder="Lastklass"
        options={OPTIONS.lastklass.value}
        required={true}
        value={form.lastklass.value}
        onChange={(e?: any) => handleOnChangeSelect(e, OPTIONS.lastklass.id)}
        infoButton={true}
        error={errors.lastklass}
        helperText={"Ange lastklass"}
        onClickInfo={() =>
          handleOnClickLocalInfo({
            title: "Lastklass",
            info: `Avtalad lastklass avser hur mycket material som ska infinnas på ställningen. Normalt innebär detta att enbart ETT bomlag belastas samtidigt med avtalad lastklass,
            då typkontrollintygen generellt utfärdas med enbart ett belastat bomlag.<br><br>
            "Det är särskilt viktigt att intagsbryggor är dimensionerade
            för den last de är avsedda för."<br><br><ul><li>AFS2013:4, §69, Allmänna råd</li></ul>`,
          })
        }
      />
      <Select
        id={OPTIONS.väderskyddstak.id}
        placeholder="Väderskyddstak"
        options={OPTIONS.väderskyddstak.value}
        required={true}
        value={form.väderskyddstak.value}
        onChange={(e?: any) =>
          handleOnChangeSelect(e, OPTIONS.väderskyddstak.id)
        }
        infoButton={true}
        error={errors.väderskyddstak}
        helperText={"Ange väderskyddstak"}
        onClickInfo={() =>
          handleOnClickLocalInfo({
            title: "Väderskydd",
            info: `Väderskydd skall ha dimensioneringshandlingar som påvisar att konstruktionen är dimensionerad med betryggande säkerhet mot materialbrott, instabilitet och deformationer. (AFS 2013:4, §40, §42).`,
          })
        }
      />
    </Fragment>
  );

  return out;
};

type MiscProps = {
  handleOnChangeSelect?: any;
  handleOnClickLocalInfo?: any;
  form: any;
  errors?: any;
};
const Misc: FC<MiscProps> = ({
  handleOnChangeSelect,
  handleOnClickLocalInfo,
  form,
  errors,
}) => {
  let out = (
    <Fragment>
      <Select
        id={OPTIONS.bygghiss.id}
        placeholder="Bygghiss"
        options={OPTIONS.bygghiss.value}
        value={form.bygghiss.value}
        required={true}
        onChange={(e?: any) => handleOnChangeSelect(e, OPTIONS.bygghiss.id)}
        infoButton={true}
        error={errors.bygghiss}
        helperText={"Ange bygghiss"}
        onClickInfo={() =>
          handleOnClickLocalInfo({
            title: "Bygghiss",
            info: `Om bygghiss är förankrad i ställning frångår ställningen typkontroll och särskilda beräkningar ska kunna visa på dess hållfasthet.`,
          })
        }
      />

      <Select
        id={OPTIONS.typavarbete.id}
        placeholder="Typ av arbete"
        options={OPTIONS.typavarbete.value}
        value={form.typavarbete.value}
        required={true}
        error={errors.typavarbete}
        helperText={"Ange typ av arbete"}
        onChange={(e?: any) => handleOnChangeSelect(e, OPTIONS.typavarbete.id)}
      />
      <Select
        id={OPTIONS.förankringsyta.id}
        placeholder="Förankringsyta"
        options={OPTIONS.förankringsyta.value}
        value={form.förankringsyta.value}
        required={true}
        onChange={(e?: any) =>
          handleOnChangeSelect(e, OPTIONS.förankringsyta.id)
        }
        infoButton={true}
        error={errors.förankringsyta}
        helperText={"Ange förankringsyta"}
        onClickInfo={() =>
          handleOnClickLocalInfo({
            title: "Förankringsyta",
            info: `Valet av förankringsyta påverkar förankringarnas hållfastighet.<br>Följande värden kan användas som riktvärden men är inte tillförlitliga: <br><ul>
            <li>Betong - 13 kN</li>
            <li>Tegelsten - 10 kN</li>
            <li>Trä - 6 kN</li>
            <li>Fog - 3 kN</li>
            </ul>
            `,
          })
        }
      />
    </Fragment>
  );

  return out;
};
export default Control;
